import { gql, useQuery } from "@apollo/client";

import { ConsentStatus } from "../../twilio/types";
import { useAtomValue, useSetAtom } from "jotai";
import {
  patientConsentAtom,
  providerConsentAtom,
} from "../../twilio/messages/state";
import { useRecordAudioCallbacks } from "./useRecordAudioCallbacks";
import { autoStartRecordingGate } from "../../statsig/gates";
import { sessionInfoAtom } from "../../state";
import { Statsig } from "../../statsig/StatsigProvider";

export type ProviderPatientConsentVariable = {
  providerShortId?: string;
  patientShortId?: string;
};
export type ProviderPatientConsentData = {
  clientTranscriptionConsent?: {
    isOptedIn: boolean;
  };
  providerTranscriptionConsent?: {
    isOptedIn: boolean;
  };
};
export const GET_PROVIDER_PATIENT_CONSENT = gql`
  query GetProviderPatientTranscriptionConsent(
    $patientShortId: String!
    $providerShortId: String!
  ) {
    clientTranscriptionConsent(patientShortId: $patientShortId) {
      isOptedIn
    }
    providerTranscriptionConsent(providerShortId: $providerShortId) {
      isOptedIn
    }
  }
`;

export function optedInToConsentStatus(optedIn?: boolean): ConsentStatus {
  if (optedIn === undefined) return ConsentStatus.UNDECIDED;

  return optedIn ? ConsentStatus.OPTED_IN : ConsentStatus.OPTED_OUT;
}

/**
 * Automatically fetches/returns the provider and patient consent statuses for
 * transcription. If both have opted in & there is a scheduled appointment, it will also start recording.
 */
export function useGetProviderPatientConsent(
  providerShortId?: string,
  patientShortId?: string,
) {
  const setProviderTranscriptionConsent = useSetAtom(providerConsentAtom);
  const setPatientTranscriptionConsent = useSetAtom(patientConsentAtom);
  const sessionInfo = useAtomValue(sessionInfoAtom);

  const { startRecording } = useRecordAudioCallbacks();

  const handleRequestSuccess = (data: ProviderPatientConsentData) => {
    const providerOptIn = data?.providerTranscriptionConsent?.isOptedIn;
    const patientOptIn = consents.data?.clientTranscriptionConsent?.isOptedIn;

    const appointmentShortId = sessionInfo?.appointment.shortId;
    const providerConsent = optedInToConsentStatus(providerOptIn);
    const patientConsent = optedInToConsentStatus(patientOptIn);
    setProviderTranscriptionConsent({
      value: providerConsent,
      lastUpdated: Date.now(),
    });
    setPatientTranscriptionConsent({
      value: patientConsent,
      lastUpdated: Date.now(),
    });

    if (
      Statsig.checkGate(autoStartRecordingGate) &&
      providerConsent === ConsentStatus.OPTED_IN &&
      patientConsent === ConsentStatus.OPTED_IN &&
      appointmentShortId
    ) {
      startRecording();
    }
  };

  const consents = useQuery<
    ProviderPatientConsentData,
    ProviderPatientConsentVariable
  >(GET_PROVIDER_PATIENT_CONSENT, {
    variables: { providerShortId, patientShortId },
    skip: !providerShortId || !patientShortId,
    context: { skipAuth: false },
    onCompleted: handleRequestSuccess,
  });

  const providerConsent = useAtomValue(providerConsentAtom).value;
  const patientConsent = useAtomValue(patientConsentAtom).value;
  return {
    providerConsent,
    patientConsent,
  };
}
