import { RecordingControl as BaseRecordingControl } from "../../twilio/audio-video-controls/RecordingControl";
import { useCombineAudioTrackMediaStreams } from "./useCombineAudioTracks";
import { useGetProviderPatientConsent } from "./useGetProviderPatientConsent";
import { ConsentStatus } from "../../twilio/types";
import toast from "react-hot-toast";
import { secondsToMilliseconds } from "date-fns";
import { RecordingControlMultipleParticipantsToast } from "../../twilio/audio-video-controls/RecordingControlMultipleParticipantsToast";
import { useState, useEffect, useCallback } from "react";
import { ConsentModal } from "./ConsentModal";
import { useAtomValue, useSetAtom } from "jotai";
import { inSessionPatientInformationAtom, providerShortIdAtom } from "../state";
import { useRecordProviderTranscriptConsentMutation } from "./useRecordProviderTranscriptionConsentMutation";
import { useRequestingClientConsentToast } from "./useRequestingClientConsentToast";
import classNames from "classnames";
import { TRANSCRIPTION_NOT_AVAILABLE_FOR_MULTIPLE_PARTICIPANTS } from "../../twilio/audio-video-controls/constants";
import { ControlPanelButton } from "../../components";
import { Tooltip } from "@grow-therapy-team/sprout-ui";
import { patientConsentAtom } from "../../twilio/messages/state";
import { useRecordAudioCallbacks } from "./useRecordAudioCallbacks";
import { useSendProviderParticipantStateCallback } from "../messages/useSendProviderParticipantStateCallback";
import { useTrackEvent } from "../../segment/segment";
import { EventTypeEnum } from "../../segment/types";
import { useAtomCallback } from "jotai/utils";
import { UserType } from "../../types";
import { participantCountAtom } from "../../twilio";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { useShouldDisableTranscriptionForMultipleParticipants } from "../../twilio/audio-video-controls/useShouldDisableTranscriptionForMultipleParticipants";
import { useRecordingControl } from "./useRecordingControl";

/**
 * TODO: Extract contents to parent once feature is launched
 */
function CombineAudioTrackMediaStreams() {
  useCombineAudioTrackMediaStreams();

  return null;
}

export function RecordingControl() {
  const { userClicked } = useTrackEvent();
  const hasMultipleParticipants = useAtomValue(participantCountAtom) > 1;

  const getAppointmentShortId = useAtomCallback(
    (get) => get(inSessionPatientInformationAtom)?.inSessionAppointmentShortId,
  );

  useRequestingClientConsentToast();
  const { patientShortId } =
    useAtomValue(inSessionPatientInformationAtom) ?? {};
  const providerShortId = useAtomValue(providerShortIdAtom);
  const setClientTranscriptionConsent = useSetAtom(patientConsentAtom);
  const [
    hasDisabledTranscriptionForMultipleParticipants,
    setHasDisabledTranscriptionForMultipleParticipants,
  ] = useState(false);
  const sendParticipantState = useSendProviderParticipantStateCallback();
  const { isRecording, isVisible } = useRecordingControl();
  const { startRecording, stopRecording } = useRecordAudioCallbacks();

  const recordProviderConsent = useRecordProviderTranscriptConsentMutation(
    providerShortId,
    patientShortId,
  );
  const { providerConsent, patientConsent } = useGetProviderPatientConsent(
    providerShortId,
    patientShortId,
  );
  const requestingClientConsent = patientConsent === ConsentStatus.DECIDING;

  const [showConsentModal, setShowConsentModal] = useState(false);

  const toggleRecording = useCallback(
    async (shouldRecord: boolean) => {
      if (shouldRecord) {
        await startRecording();
      } else {
        await stopRecording();
      }
      sendParticipantState();
    },
    [startRecording, stopRecording, sendParticipantState],
  );

  /**
   * The below code is to prevent sessions with multiple participants from surfacing
   * Transcription features due to AI quality issues identified in these types of sessions.
   * It is behind a feature flag and can be removed when we improve AI features for these sessions.
   */

  const shouldShowMultipleParticipantTranscriptionDisabled =
    useShouldDisableTranscriptionForMultipleParticipants();

  const shouldDisableTranscription =
    isRecording &&
    !hasDisabledTranscriptionForMultipleParticipants &&
    shouldShowMultipleParticipantTranscriptionDisabled;

  useEffect(() => {
    const handleDisableTranscription = async () => {
      await toggleRecording(false);
      toast.custom(RecordingControlMultipleParticipantsToast, {
        duration: secondsToMilliseconds(10),
        position: "bottom-center",
      });
      setHasDisabledTranscriptionForMultipleParticipants(true);
    };
    if (shouldDisableTranscription) {
      handleDisableTranscription();
    }
    if (!hasMultipleParticipants) {
      setHasDisabledTranscriptionForMultipleParticipants(false);
    }
  }, [
    hasMultipleParticipants,
    isRecording,
    shouldDisableTranscription,
    toggleRecording,
  ]);

  const requestClientConsent = () => {
    setClientTranscriptionConsent({
      value: ConsentStatus.DECIDING,
      lastUpdated: Date.now(),
    });
    sendParticipantState();
  };

  const recordOptIn = async () => {
    const { data } = await recordProviderConsent(true);
    const providerIsOptedIn =
      data?.recordProviderTranscriptionConsent?.isOptedIn;
    if (providerIsOptedIn && patientConsent !== ConsentStatus.OPTED_IN) {
      requestClientConsent();
    } else if (providerIsOptedIn && patientConsent === ConsentStatus.OPTED_IN) {
      toggleRecording(true);
    }
    setShowConsentModal(false);
    userClicked(EventTypeEnum.GAVE_TRANSCRIPTION_CONSENT, {
      appointmentShortId: getAppointmentShortId()!,
      entityType: UserType.PROVIDER,
    });
  };

  const bothPartiesConsented =
    patientConsent === ConsentStatus.OPTED_IN &&
    providerConsent === ConsentStatus.OPTED_IN;

  if (!isVisible || providerConsent === ConsentStatus.OPTED_OUT) return null;

  return (
    <>
      {showConsentModal && (
        <ConsentModal
          onClose={() => setShowConsentModal(false)}
          recordOptIn={recordOptIn}
          isOpen={showConsentModal}
        />
      )}
      <CombineAudioTrackMediaStreams />
      {shouldShowMultipleParticipantTranscriptionDisabled ? (
        <Tooltip
          text={TRANSCRIPTION_NOT_AVAILABLE_FOR_MULTIPLE_PARTICIPANTS}
          forceInteractive={false}
        >
          <ControlPanelButton
            disabled={hasMultipleParticipants}
            aria-label={TRANSCRIPTION_NOT_AVAILABLE_FOR_MULTIPLE_PARTICIPANTS}
            iconDefinition={faPlay}
            data-testid="recording-control"
          />
        </Tooltip>
      ) : (
        <BaseRecordingControl
          className={classNames({
            "bg-lilac-700": requestingClientConsent || bothPartiesConsented,
          })}
          isRecording={isRecording}
          consentPending={!bothPartiesConsented}
          onRecordingToggle={(shouldRecord: boolean) => {
            toggleRecording(shouldRecord);
            userClicked(EventTypeEnum.CLICK_RECORDING_TOGGLE, {
              appointmentShortId: getAppointmentShortId()!,
              newRecordingStatus: shouldRecord ? "PLAY" : "PAUSE",
              entityType: UserType.PROVIDER,
            });
          }}
          onPendingConsentClick={() => {
            if (providerConsent !== ConsentStatus.OPTED_IN) {
              setShowConsentModal(true);
            } else {
              requestClientConsent();
            }
          }}
        />
      )}
    </>
  );
}
