import { DrawerBody, Tabs, Tab } from "@grow-therapy-team/sprout-ui";
import { useAtom } from "jotai";
import { activeClientInformationTabAtom } from "./state";
import { ClientInformationTabWrapper as ClientInformationTab } from "./ClientInformationTab";
import { UpcomingClientAppointmentsTabWrapper as UpcomingClientAppointmentsTab } from "./client-appointments-tab/UpcomingClientAppointmentsTab";
import { TreatmentTabWrapper as TreatmentTab } from "./treatment-tab/TreatmentTab";
import { ClientInformationDrawerTab } from "../state";
import {
  faCalendar,
  faHeadSideMedical,
  faIdCardClip,
} from "@fortawesome/pro-solid-svg-icons";

export function ClientInformationDrawerBody({
  tabContent,
  setActiveTab,
  activeTab,
}: {
  tabContent: React.ReactNode;
  setActiveTab: (tab: ClientInformationDrawerTab) => void;
  activeTab: ClientInformationDrawerTab;
}) {
  return (
    <DrawerBody>
      <Tabs
        selectedTabId={activeTab}
        onSelectTab={(newTab: ClientInformationDrawerTab) => {
          setActiveTab(newTab);
        }}
        className="whitespace-nowrap"
      >
        <Tab
          tabId={ClientInformationDrawerTab.INFORMATION}
          className="whitespace-nowrap"
          icon={faIdCardClip}
          title="Overview"
        />
        <Tab
          tabId={ClientInformationDrawerTab.TREATMENT}
          className="whitespace-nowrap"
          icon={faHeadSideMedical}
          title="Treatment"
        />
        <Tab
          tabId={ClientInformationDrawerTab.APPOINTMENTS}
          className="whitespace-nowrap"
          icon={faCalendar}
          title="Appointments"
        />
      </Tabs>
      <div className="pt-5">{tabContent}</div>
    </DrawerBody>
  );
}

export function ClientInformationDrawerBodyWrapper({
  isEmpty,
  selectedPatientShortId,
}: {
  isEmpty: boolean;
  selectedPatientShortId: string;
}) {
  const [activeTab, setActiveTab] = useAtom(activeClientInformationTabAtom);
  const clientTabMap = {
    [ClientInformationDrawerTab.INFORMATION]: (
      <>
        <ClientInformationTab
          isEmpty={isEmpty}
          selectedPatientShortId={selectedPatientShortId}
        />
      </>
    ),
    [ClientInformationDrawerTab.TREATMENT]: (
      <>
        <TreatmentTab selectedPatientShortId={selectedPatientShortId} />
      </>
    ),
    [ClientInformationDrawerTab.APPOINTMENTS]: (
      <>
        <UpcomingClientAppointmentsTab
          selectedPatientShortId={selectedPatientShortId}
        />
      </>
    ),
  };

  const tabContent = clientTabMap[activeTab];

  return (
    <ClientInformationDrawerBody
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      tabContent={tabContent}
    />
  );
}
