import { gql } from "@apollo/client";
import {
  MeasureSchemaFieldObject,
  MeasureSchemaFieldOptionObject,
  MeasureSchemaObject,
  MeasureSchemaSectionObject,
} from "@grow-therapy-team/sprout-ui";

export const MEASURES_CONTENT_FRAGMENTS = gql`
  fragment MeasuresContent_MeasureSchemaFields on MeasureSchemaObject {
    isProviderSpecific
    sections {
      key
      visibleConditionName
      fields {
        key
        visibleConditionName
        options {
          score
          key
        }
      }
    }
  }
`;

type Option = Pick<
  MeasureSchemaFieldOptionObject,
  "score" | "key" | "__typename"
>;
type Field = {
  options: Option[];
} & Pick<
  MeasureSchemaFieldObject,
  "key" | "visibleConditionName" | "__typename"
>;
type Section = {
  fields: Field[];
} & Pick<
  MeasureSchemaSectionObject,
  "key" | "visibleConditionName" | "__typename"
>;

export type MeasuresContent_MeasureSchemaFieldsFragment = {
  sections: Section[];
} & Pick<MeasureSchemaObject, "isProviderSpecific" | "__typename">;
