import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  ProgressBar,
} from "@grow-therapy-team/sprout-ui";
import { twMerge } from "tailwind-merge";
import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";
import { DrawerHeader } from "./DrawerHeader";

type ProgressBarProps = Parameters<typeof ProgressBar>[0];

export type BasePaginatedDrawerProps<T> = {
  /**
   * The component to render the actions in drawer's footer.
   */
  actionsComponent?: FC<PageComponentProps<T>>;
  /**
   * If true, then this component will only return the header, body, and
   * footer of the drawer.
   */
  contentOnly?: boolean;
  /**
   * The index of the page to display initially.
   */
  initialPageIndex?: number;
  /**
   * The component to render the content of the drawer's body.
   */
  contentComponent: FC<PageComponentProps<T>>;
  countdown?: string | null;
  countdownComponent?: FC<{ countdown?: string }>;

  drawerBodyProps?: Omit<Parameters<typeof DrawerBody>[0], "children">;
  /**
   * The component to render the header of the drawer.
   */
  headerComponent: FC<PageComponentProps<T>>;
  backButtonDisabled?: boolean;
  progressBarProps?: Partial<ProgressBarProps>;
  footerClassName?: string;

  /**
   * A function that should return a unique key for the body of the drawer. This
   * is used to force the drawer body to re-render when the key changes, and is
   * useful for resetting scroll position.
   */
  drawerBodyKeyFn?: (props: PageComponentProps<T>) => string;

  pages: T[];
};

export type PaginatedDrawerPropsWithoutDrawer<T> =
  BasePaginatedDrawerProps<T> & {
    contentOnly: true;
  };

export type PaginatedDrawerPropsWithDrawer<T> = BasePaginatedDrawerProps<T> & {
  contentOnly?: false;
} & Parameters<typeof Drawer>[0];

export type PaginatedDrawerProps<T> =
  | PaginatedDrawerPropsWithoutDrawer<T>
  | PaginatedDrawerPropsWithDrawer<T>;

export type PageComponentProps<T> = {
  page: T;
  pageIndex: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  nextPage: () => void;
  prevPage: () => void;
  backButtonDisabled?: boolean;
  countdown?: string | null;
  setPage: Dispatch<SetStateAction<T>>;
  scrollToTop: () => void;
};

export function PaginatedDrawer<T>({
  actionsComponent: ActionsComponent,
  contentComponent: ContentComponent,
  countdownComponent: CountdownComponent,
  drawerBodyProps,
  contentOnly,
  backButtonDisabled,
  countdown,
  footerClassName,
  initialPageIndex = 0,
  pages: initialPages,
  headerComponent: HeaderComponent,
  drawerBodyKeyFn,
  ...drawerProps
}: PaginatedDrawerProps<T>) {
  const [pages, setPages] = useState(initialPages);
  const [pageIndex, setPageIndex] = useState<number>(initialPageIndex);

  const isFirstPage = pageIndex === 0;
  const isLastPage = pageIndex === pages.length - 1;

  const contentRef = useRef<HTMLDivElement | null>(null);

  const componentProps = useMemo<PageComponentProps<T>>(
    () => ({
      page: pages[pageIndex],
      pageIndex,
      isFirstPage,
      isLastPage,
      nextPage: () => !isLastPage && setPageIndex(pageIndex + 1),
      prevPage: () => !isFirstPage && setPageIndex(pageIndex - 1),
      backButtonDisabled,
      countdown,
      setPage: (newPage: T | ((prevPage: T) => T)) => {
        setPages((prevPages) => {
          const updatedPages = [...prevPages];
          const updatedPage =
            // handle both forms of useState(value), useState(prev => value)
            typeof newPage === "function"
              ? (newPage as (prevPage: T) => T)(updatedPages[pageIndex])
              : newPage;
          updatedPages[pageIndex] = updatedPage;
          return updatedPages;
        });
      },
      scrollToTop: () => {
        if (contentRef.current) {
          contentRef.current.scrollIntoView({ behavior: "instant" });
        }
      },
    }),
    [
      pages,
      pageIndex,
      setPageIndex,
      setPages,
      isFirstPage,
      isLastPage,
      backButtonDisabled,
      countdown,
    ],
  );
  const [Container, otherProps] = contentOnly
    ? [Fragment, {}]
    : [Drawer, drawerProps];
  const drawerBodyKeyProps = drawerBodyKeyFn
    ? {
        key: drawerBodyKeyFn(componentProps),
      }
    : {};

  return (
    <Container {...otherProps}>
      <DrawerHeader>
        <HeaderComponent {...componentProps} />
      </DrawerHeader>
      <DrawerBody
        {...{
          ...drawerBodyProps,
          ...drawerBodyKeyProps,
        }}
        ref={contentRef}
      >
        <ContentComponent {...componentProps} />
      </DrawerBody>
      <DrawerFooter>
        <div className="flex flex-col w-full justify-between">
          <div
            className={twMerge(
              "flex w-full gap-4 justify-between",
              footerClassName,
            )}
          >
            <ProgressBar
              currentStepIndex={pageIndex}
              totalSteps={pages.length}
              className="justify-center pr-3"
            />
            {ActionsComponent && <ActionsComponent {...componentProps} />}
          </div>
          {countdown && CountdownComponent && (
            <CountdownComponent countdown={countdown} />
          )}
        </div>
      </DrawerFooter>
    </Container>
  );
}
