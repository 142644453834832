import { DEFAULT_APP_TITLE } from "../../config";
import { setDefaultFavicon, setTitle } from "../../metadata";

export const DEFAULT_WAITING_ROOM_PAGE_TITLE = `${DEFAULT_APP_TITLE} - Waiting Room`;

/**
 * Sets the default title for the waiting room
 */
export function setDefaultWaitingRoomTitle() {
  setTitle(DEFAULT_WAITING_ROOM_PAGE_TITLE);
}

/**
 * Sets the default favicon and title for the waiting room
 */
export function setDefaultWaitingRoomFaviconAndTitle() {
  setDefaultFavicon();
  setDefaultWaitingRoomTitle();
}
