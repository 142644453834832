import { useCountdown } from "../../hooks/useCountdown";
import { useAtomValue, useSetAtom } from "jotai";
import { deadlineAtom } from "../waiting-room/delayed-entry/state";
import {
  visitorClientShortIdAtom,
  visitorDrawerStateAtom,
  visitorGroupedFormsAtom,
  visitorMeasuresAtom,
} from "../state";
import { GroupedFormData } from "../../components/forms/types";
import { PageComponentProps, PaginatedDrawer } from "../../components";
import { PendingFormsDrawerHeader } from "../../components/forms/PendingFormsDrawerHeader";
import { ClientPendingFormsWrapper as PendingForm } from "./ClientPendingFormsWrapper";
import { ClientPendingFormsDrawerActions } from "./ClientPendingFormsDrawerAction";
import { ProviderReadyToSeeYouCard } from "../waiting-room/delayed-entry/ProviderReadyToSeeYouCard";
import { useShouldUseMICInfra } from "../../hooks/measures/useShouldUseMICInfra";
import { MeasuresDrawer } from "../../components/measures/MeasuresDrawer";
import { MeasuresContent } from "../../components/measures/MeasuresContent";
import { MeasuresActionsWrapper as BaseMeasuresActions } from "../../components/measures/MeasuresActions";
import { MeasureSchemaAndAnswers } from "../../components/measures/types";
import { usePreSessionPromptState } from "../waiting-room/usePreSessionPromptState";
import { useParams } from "react-router-dom";
import { MeasureResponseCompletionCampaign } from "@grow-therapy-team/sprout-ui";

export function ClientMeasuresDrawer({
  countdown,
}: {
  countdown: string | null;
}) {
  const visitorForms = useAtomValue(visitorGroupedFormsAtom);

  if (!visitorForms) return undefined;

  return (
    <>
      <PaginatedDrawer<GroupedFormData>
        contentComponent={PendingForm}
        contentOnly
        drawerBodyProps={{ className: "bg-neutral-200" }}
        headerComponent={PendingFormsDrawerHeader}
        countdownComponent={ProviderReadyToSeeYouCard}
        pages={visitorForms}
        countdown={countdown}
        actionsComponent={ClientPendingFormsDrawerActions}
        drawerBodyKeyFn={({ page }) => `pending-forms-drawer.${page?.formId}`}
      />
    </>
  );
}

function MeasuresActionsWrapper(
  props: PageComponentProps<MeasureSchemaAndAnswers>,
) {
  const clientUserShortId = useAtomValue(visitorClientShortIdAtom);
  const { providerShortId } = useParams();
  const setDrawerState = useSetAtom(visitorDrawerStateAtom);
  const { clearPreSessionPrompt } = usePreSessionPromptState();
  const setMeasures = useSetAtom(visitorMeasuresAtom);

  const handleSuccessfulSubmission = () => {
    if (props.isLastPage) {
      setMeasures([]);
      setDrawerState(null);
      clearPreSessionPrompt();
    }
  };

  return (
    <BaseMeasuresActions
      {...props}
      clientUserShortId={clientUserShortId}
      providerShortId={providerShortId}
      completionCampaign={MeasureResponseCompletionCampaign.WAITING_ROOM}
      onSubmitMeasureSuccess={handleSuccessfulSubmission}
    />
  );
}

function PendingMeasuresDrawerWrapper({
  countdown,
}: {
  countdown: string | null;
}) {
  const measures = useAtomValue(visitorMeasuresAtom);
  const pages = measures?.map((schema) => ({
    schema: schema.schema,
    hasSubmitted: false,
    answers: {},
    errors: [],
    provider: schema.providers?.[0] ?? undefined,
  }));

  return (
    pages?.length && (
      <MeasuresDrawer
        pages={pages}
        contentComponent={MeasuresContent}
        actionsComponent={MeasuresActionsWrapper}
        countdown={countdown}
        countdownComponent={ProviderReadyToSeeYouCard}
      />
    )
  );
}

export function ClientMeasuresDrawerWrapper() {
  const deadline = useAtomValue(deadlineAtom);
  const setDrawerState = useSetAtom(visitorDrawerStateAtom);
  const countdown = useCountdown({
    deadline,
    onFinish: () => setDrawerState(null),
  });
  const shouldUseMICInfra = useShouldUseMICInfra();

  if (shouldUseMICInfra) {
    return <PendingMeasuresDrawerWrapper countdown={countdown} />;
  } else {
    return <ClientMeasuresDrawer countdown={countdown} />;
  }
}
