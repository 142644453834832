import { toast } from "react-hot-toast";
import { minutesToMilliseconds } from "date-fns";
import { deviceIsMobile } from "../../utils";
import { useEndOfSessionToast } from "../../hooks/useEndOfSessionToast";
import { PromptFollowUpToastWrapper as PromptFollowUpToast } from "./PromptFollowUpToast";
import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { twilioRoomAtom } from "../../twilio";
import { useSetAtom } from "jotai";
import {
  ClientInformationDrawerTab,
  DrawerState,
  drawerStateAtom,
} from "../state";
import { activeClientInformationTabAtom } from "../client-information/state";

const NUDGE_PRIOR_END_TIME_MS = minutesToMilliseconds(5);

export function usePromptFollowUpToast(
  providerShortId: string | undefined,
  patientShortId: string | undefined,
) {
  const isMobile = deviceIsMobile();
  const getIsInSession = useAtomCallback(
    useCallback((get) => !!get(twilioRoomAtom), []),
  );
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setActiveTab = useSetAtom(activeClientInformationTabAtom);

  const hideToast = useCallback((toastId: string) => {
    if (!toastId) return;
    toast.remove(toastId);
  }, []);

  const showToast = () => {
    const position = isMobile ? "bottom-center" : "bottom-right";
    toast.custom(
      ({ id: toastId }) => {
        return (
          <PromptFollowUpToast
            onClose={() => hideToast(toastId)}
            className={`${position}-toast`}
            onClickScheduleFollowUp={() => {
              hideToast(toastId);
              setDrawerState(DrawerState.CLIENT_INFORMATION);
              setActiveTab(ClientInformationDrawerTab.APPOINTMENTS);
            }}
          />
        );
      },
      {
        duration: minutesToMilliseconds(1),
        position: position,
      },
    );
  };

  useEndOfSessionToast(
    providerShortId,
    patientShortId,
    showToast,
    NUDGE_PRIOR_END_TIME_MS,
    getIsInSession(),
  );
}
