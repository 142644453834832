import {
  Button,
  ButtonType,
  DRAWER_TITLE_ID,
  DrawerBody,
  DrawerFooter,
  Icon,
  IconButton,
  Tag,
  TagUse,
  Text,
  TextInput,
} from "@grow-therapy-team/sprout-ui";
import {
  ReactNode,
  useEffect,
  useRef,
  useState,
  useCallback,
  MutableRefObject,
  RefObject,
} from "react";
import { Message } from "@twilio/conversations";
import { ChatBubble } from "./ChatBubble";
import { scrollToBottom } from "../utils";
import { DrawerHeader } from "../DrawerHeader";
import { Toast, ToastVariant } from "..";
import { ParticipantStatus } from "../../types";
import {
  faArrowDown,
  faArrowLeftLong,
  faPaperPlaneTop,
  faWarning,
} from "@fortawesome/pro-solid-svg-icons";

export interface ChatDrawerWrapperProps {
  identity?: string;
  chatName?: string;
  messages: Message[];
  onSend: (message: string) => void;
  quickReplyComponent?: ReactNode;
  connectedAt?: number;
  footerText?: string;
  headerText?: string;
  emptyState?: ReactNode;
  isEmptyState?: boolean;
  chatStatus?: ParticipantStatus;
  onGoToChatList?: () => void;
  sendIsDisabled?: boolean;
  isError?: boolean;
}
export interface ChatDrawerProps extends ChatDrawerWrapperProps {
  hasUnseenNewMessages: boolean;
  setHasUnseenNewMessages: (hasUnseenNewMessages: boolean) => void;
  scrollContainer: RefObject<HTMLDivElement>;
  isAtBottom: MutableRefObject<boolean>;
  handleScrollToBottom: () => void;
}
function ChatError() {
  return (
    <Toast
      className="bg-transparent mb-4"
      data-testid="chat-drawer.error"
      variant={ToastVariant.Neutral}
    >
      <span>
        <Icon className="text-coral-600 mr-4" icon={faWarning} aria-hidden />
        Unable to connect to chat.
      </span>
    </Toast>
  );
}

export function ChatDrawerWrapper({
  identity,
  chatName,
  messages,
  onSend,
  quickReplyComponent,
  connectedAt,
  footerText,
  headerText,
  emptyState,
  isEmptyState,
  chatStatus,
  onGoToChatList,
  sendIsDisabled,
  isError,
}: ChatDrawerWrapperProps) {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const isAtBottom = useRef(true);

  const [hasUnseenNewMessages, setHasUnseenNewMessages] =
    useState<boolean>(false);

  const handleScrollToBottom = () => {
    if (scrollContainer.current) {
      scrollToBottom(scrollContainer.current);
      setHasUnseenNewMessages(false);
      isAtBottom.current = true;
    }
  };
  return (
    <ChatDrawer
      identity={identity}
      chatName={chatName}
      messages={messages}
      onSend={onSend}
      quickReplyComponent={quickReplyComponent}
      connectedAt={connectedAt}
      footerText={footerText}
      headerText={headerText}
      emptyState={emptyState}
      isEmptyState={isEmptyState}
      chatStatus={chatStatus}
      onGoToChatList={onGoToChatList}
      sendIsDisabled={sendIsDisabled}
      hasUnseenNewMessages={hasUnseenNewMessages}
      setHasUnseenNewMessages={setHasUnseenNewMessages}
      scrollContainer={scrollContainer}
      isAtBottom={isAtBottom}
      handleScrollToBottom={handleScrollToBottom}
      isError={isError}
    />
  );
}

export function ChatDrawer({
  identity,
  chatName,
  messages,
  onSend,
  quickReplyComponent,
  connectedAt,
  footerText,
  headerText,
  emptyState,
  isEmptyState,
  chatStatus,
  onGoToChatList,
  sendIsDisabled,
  isError,
  isAtBottom,
  scrollContainer,
  handleScrollToBottom,
  hasUnseenNewMessages,
  setHasUnseenNewMessages,
}: ChatDrawerProps) {
  const [messageDraft, setMessageDraft] = useState<string>("");

  const shouldShowDate = !!connectedAt || messages.length > 0;
  const buttonIsDisabled = !messageDraft.trim() || sendIsDisabled || isError;

  /**
   * This - 20 is an arbitrary buffer we use to prevent behavior related to scroll position
   * if the user is slightly above the bottom of the scroll container.
   * It represents 20px
   **/
  const BOTTOM_SCROLL_BUFFER_PX = 20;

  const handleScroll = useCallback(
    () => {
      const container = scrollContainer.current;
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const atBottom =
          scrollTop + clientHeight >= scrollHeight - BOTTOM_SCROLL_BUFFER_PX;
        isAtBottom.current = atBottom;
        if (atBottom) setHasUnseenNewMessages(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    function initializeScrollListener() {
      const container = scrollContainer.current;
      if (container) {
        container.addEventListener("scroll", handleScroll);
        handleScrollToBottom();
      }
      return () => container?.removeEventListener("scroll", handleScroll);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleScroll],
  );

  useEffect(
    function manageScrollOnNewMessages() {
      if (isAtBottom.current) {
        handleScrollToBottom();
      } else {
        setHasUnseenNewMessages(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages],
  );

  const onSendMessage = () => {
    onSend(messageDraft);
    setMessageDraft("");
    if (isAtBottom.current) handleScrollToBottom();
  };

  const screenReaderHeaderTitle = chatName
    ? `Chat conversation with ${chatName}`
    : "Chat conversation";
  const screenReaderHeaderStatus = chatStatus
    ? `, participant ${chatStatus.toLowerCase()}`
    : "";

  return (
    <>
      <DrawerHeader>
        {onGoToChatList && (
          <button
            className="text-lilac-800 flex items-center"
            onClick={onGoToChatList}
          >
            <Icon icon={faArrowLeftLong} className="mr-2 w-3" aria-hidden />
            <Text as="span" variant="xs" className="font-medium">
              <span className="sr-only">Return to list of all chats</span>
              <span aria-hidden>ALL CHATS</span>
            </Text>
          </button>
        )}
        <span className="sr-only" id={DRAWER_TITLE_ID}>
          {screenReaderHeaderTitle + screenReaderHeaderStatus}
        </span>
        <div aria-hidden className="flex items-center w-full">
          <div
            className="fs-exclude"
            data-dd-privacy="mask"
            data-dd-action-name="Click on chat name"
          >
            {chatName || "Chat"}
          </div>
          {chatStatus && (
            <Tag
              className="ml-2"
              use={
                chatStatus === ParticipantStatus.IN_SESSION
                  ? TagUse.Green
                  : TagUse.Neutral
              }
            >
              {chatStatus}
            </Tag>
          )}
        </div>
      </DrawerHeader>
      {headerText && (
        <div className="px-8 py-3">
          <Text variant="xs" className="text-neutral-700">
            {headerText}
          </Text>
        </div>
      )}
      {isEmptyState ? (
        <DrawerBody>{emptyState}</DrawerBody>
      ) : (
        <>
          <DrawerBody
            className="bg-neutral-200 h-full overflow-y-auto"
            ref={scrollContainer}
            aria-label={`Chat conversation with ${chatName}`}
          >
            <Text
              variant="xs"
              className="block text-neutral-700 text-center pb-6"
            >
              {connectedAt &&
                `Session started at ${new Date(connectedAt).toLocaleTimeString(
                  [],
                  {
                    hour: "numeric",
                    minute: "numeric",
                  },
                )}, `}
              {shouldShowDate &&
                new Date().toLocaleString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
            </Text>
            <ul aria-live="polite" aria-label="Chat messages">
              {messages.map((message) => {
                const isByCurrentUser = message.author === identity;
                return (
                  <ChatBubble
                    key={message.sid}
                    message={message}
                    isSelf={isByCurrentUser}
                  />
                );
              })}
            </ul>
            {!messages.length ? (
              <span className="sr-only">No messages.</span>
            ) : null}
            {footerText && (
              <div className="pt-6 mt-auto">
                <Text variant="xs" className="text-neutral-700 text-center">
                  {footerText}
                </Text>
              </div>
            )}
          </DrawerBody>
          {quickReplyComponent}
          {hasUnseenNewMessages && (
            <div className="border-t border-neutral-600 px-8 py-3 flex items-center justify-center">
              <Button onClick={handleScrollToBottom} use="secondary">
                See latest message <Icon icon={faArrowDown} />
              </Button>
            </div>
          )}
          <DrawerFooter>
            <div className="flex flex-col w-full">
              {isError && <ChatError />}
              <form
                className="flex w-full"
                onSubmit={(event) => event.preventDefault()}
              >
                <TextInput
                  onChange={(event) => setMessageDraft(event.target.value)}
                  placeholder="Compose your message"
                  className="mr-2 !bg-neutral-100"
                  value={messageDraft}
                />

                <Button
                  disabled={buttonIsDisabled}
                  onClick={onSendMessage}
                  type={ButtonType.Submit}
                  className="hidden sm:block"
                >
                  Send
                  <span className="sr-only">
                    message{chatName ? ` to ${chatName}` : ""}
                  </span>
                </Button>
                <IconButton
                  disabled={buttonIsDisabled}
                  onClick={onSendMessage}
                  type={ButtonType.Submit}
                  className="sm:hidden"
                  aria-label={`Send message${
                    chatName ? ` to ${chatName}` : ""
                  }`}
                  iconDefinition={faPaperPlaneTop}
                />
              </form>
            </div>
          </DrawerFooter>
        </>
      )}
    </>
  );
}
