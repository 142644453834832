import { useAtomValue } from "jotai";
import { useRecordingControl as useBaseRecordingControl } from "../../twilio/audio-video-controls/useRecordingControl";
import { inSessionPatientInformationAtom } from "../state";
import { useFeatureGate } from "@statsig/react-bindings";
import { telehealthTranscriptionGate } from "../../statsig/gates";

export function useRecordingControl() {
  const { value: telehealthTranscriptionIsEnabled } = useFeatureGate(
    telehealthTranscriptionGate,
  );
  const hasAppointment = !!useAtomValue(inSessionPatientInformationAtom)
    ?.inSessionAppointmentShortId;
  return useBaseRecordingControl(
    hasAppointment,
    telehealthTranscriptionIsEnabled,
  );
}
