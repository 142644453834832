import { useAtomValue } from "jotai";
import { useRecordingControl as useBaseRecordingControl } from "../../twilio/audio-video-controls/useRecordingControl";
import { sessionAppointmentShortIdAtom } from "../state";
import { transcriptionFeatureEnabledAtom } from "./state";

export function useRecordingControl() {
  const hasAppointment = !!useAtomValue(sessionAppointmentShortIdAtom);
  return useBaseRecordingControl(
    hasAppointment,
    useAtomValue(transcriptionFeatureEnabledAtom),
  );
}
