import { useCallback } from "react";
import { useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { AudioVideoTrackKind, LocalAVTrack } from "../types";
import { releaseTrack, unpublishLocalTrack } from "../tracks";
import {
  DEFAULT_LOCAL_TRACK_VALUE,
  LocalTrackState,
  localAudioVideoTrackFamily,
  twilioRoomAtom,
} from "../state";
import { logger } from "../../datadog/logger";
import { Mutex } from "async-mutex";

const trackKindToMutexMap = {
  audio: new Mutex(),
  video: new Mutex(),
};

/**
 * Returns a callback to unpublish the local track of the specified track kind, removing it from the room
 * Twilio recommends unpublishing over just disabling the track when the track is toggled off by the user
 * https://www.twilio.com/docs/video/ios-recommendations-best-practices#turn-the-camera-on-and-off
 */
export function useUnpublishLocalTrackCallback(
  trackKind: AudioVideoTrackKind,
): (opts?: { shouldReleaseTrack?: boolean }) => Promise<LocalAVTrack | void> {
  const getLocalParticipant = useAtomCallback(
    useCallback((get) => get(twilioRoomAtom)?.localParticipant, []),
  );
  const trackAtom = localAudioVideoTrackFamily(trackKind);
  const getLocalTrack = useAtomCallback(
    useCallback((get) => get(trackAtom), [trackAtom]),
  );
  const setLocalTrack = useSetAtom(trackAtom);

  return useCallback(
    async ({ shouldReleaseTrack } = {}) => {
      const release = await trackKindToMutexMap[trackKind]?.acquire();
      let prevLocalTrack;
      try {
        prevLocalTrack = getLocalTrack();
        const { track: localTrackValue } = prevLocalTrack;
        if (!localTrackValue) return;
        setLocalTrack((prev) => ({
          ...prev,
          state: LocalTrackState.LOADING,
        }));
        const track = unpublishLocalTrack(
          localTrackValue,
          getLocalParticipant(),
        );
        if (shouldReleaseTrack) {
          releaseTrack(localTrackValue);
          setLocalTrack(DEFAULT_LOCAL_TRACK_VALUE);
        } else {
          setLocalTrack((prev) => ({
            ...prev,
            state: LocalTrackState.DISABLED,
          }));
        }
        return track;
      } catch (error) {
        logger.error("Unable to unpublish local track", {}, error as Error);
        if (prevLocalTrack) {
          setLocalTrack(prevLocalTrack);
        }
      } finally {
        release?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
