import {
  ButtonSize,
  DRAWER_TITLE_ID,
  IconButton,
  Text,
} from "@grow-therapy-team/sprout-ui";
import { DrawerHeader } from "../../../components/DrawerHeader";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { useAtomValue, useSetAtom } from "jotai";
import {
  DrawerState,
  drawerStateAtom,
  selectedClientInfoAtom,
} from "../../state";

export function AddAppointmentDrawerHeader({
  onClickBackButton,
  clientName,
}: {
  onClickBackButton: () => void;
  clientName: string;
}) {
  return (
    <DrawerHeader>
      <div className="flex items-center">
        <IconButton
          onClick={onClickBackButton}
          className="[&>svg]:w-[0.75rem] mr-4 w-8 h-8 flex justify-center items-center"
          size={ButtonSize.Small}
          aria-label="Go back"
          data-testid="add-appointment.button.back"
          iconDefinition={faArrowLeft}
        />
        <span>
          <span
            className="fs-exclude"
            data-dd-privacy="mask"
            data-dd-action-name="Client Name"
            data-testid="add-appointment.header.name"
          >
            <Text
              as="span"
              variant="lg"
              className="font-medium"
              id={DRAWER_TITLE_ID}
            >
              <span className="sr-only">Add appointment for </span>
              {clientName}
            </Text>
          </span>
        </span>
      </div>
    </DrawerHeader>
  );
}

export function AddAppointmentDrawerHeaderWrapper() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  const scheduledAppointmentInfo = useAtomValue(selectedClientInfoAtom);

  return (
    <AddAppointmentDrawerHeader
      clientName={scheduledAppointmentInfo?.name || ""}
      onClickBackButton={() => setDrawerState(DrawerState.CLIENT_INFORMATION)}
    />
  );
}
