import { Button, Icon, Tag, TagUse } from "@grow-therapy-team/sprout-ui";
import {
  numWaitingVisitorsAtom,
  drawerStateAtom,
  DrawerState,
  totalUnreadMessageCountAtom,
} from "../state";
import { useAtom, useAtomValue } from "jotai";
import toast from "react-hot-toast";
import { useEffect, useMemo } from "react";
import { faMessage, faUser } from "@fortawesome/pro-solid-svg-icons";
import { participantCountAtom } from "../../twilio";
import classNames from "classnames";
import { getIsClientListDrawerOpen } from "../utils";
import { DrawerButton } from "../../components/DrawerButton";
import { DRAWER_STATES_CONTROLLED_BY_CLIENT_INFO_BUTTON } from "../client-information/constants";
import { ControlPanelButton } from "../../components";
import { setAlertTitle, setDefaultFaviconAndTitle } from "../../metadata";

function ClientsDrawerIconButton({
  drawerId,
  drawerState,
  notificationCount,
  onClick,
  isHidden,
}: {
  drawerId: string;
  drawerState: DrawerState | null;
  onClick: () => void;
  notificationCount: number;
  isHidden?: boolean;
}) {
  const isClientsDrawerOpen = getIsClientListDrawerOpen(drawerState);
  const notificationsLabel =
    notificationCount === 1
      ? "1 unread notification"
      : `${notificationCount} unread notifications"`;

  const ariaLabel = isClientsDrawerOpen
    ? `Close clients drawer${
        notificationCount ? `, ${notificationsLabel}` : ""
      }`
    : `Open clients drawer${
        notificationCount ? `, ${notificationsLabel}` : ""
      }`;

  return (
    <div
      className={classNames("relative md:hidden", {
        hidden: isHidden,
      })}
    >
      <DrawerButton
        as={ControlPanelButton}
        // NOTE: This list overlaps with the drawer states controlled by the
        // `ClientInformationButton`. This is intentional and ensures that when
        // that button is not available because a provider is not in session, this
        // button can still be focused on drawer close instead.
        forDrawerStates={[
          DrawerState.CLIENTS,
          ...DRAWER_STATES_CONTROLLED_BY_CLIENT_INFO_BUTTON,
        ]}
        aria-label={ariaLabel}
        aria-expanded={isClientsDrawerOpen}
        aria-controls={drawerId}
        data-testid="clients-drawer-button-mobile"
        onClick={onClick}
        iconDefinition={faUser}
      />
      {!!notificationCount && (
        <Tag
          aria-hidden
          use={TagUse.Lilac}
          className="absolute -top-1 -right-1 z-10 rounded-3xl"
          onClick={onClick}
        >
          {notificationCount}
        </Tag>
      )}
    </div>
  );
}

export function ClientsDrawerButton({
  drawerId,
  drawerState,
  numberUnreadMessages,
  numberWaiting,
  onClick,
}: {
  drawerId: string;
  drawerState: DrawerState | null;
  numberUnreadMessages: number;
  numberWaiting: number;
  onClick: () => void;
}) {
  const isClientsDrawerOpen = getIsClientListDrawerOpen(drawerState);
  const ariaLabel = isClientsDrawerOpen
    ? "Close clients drawer"
    : "Open clients drawer";

  useEffect(
    function showClientNotificationInFavIconAndTitle() {
      if (!numberWaiting) return setDefaultFaviconAndTitle();
      const clientNotificationTitle = `(${numberWaiting}) client${
        numberWaiting > 1 ? "s" : ""
      } waiting`;
      setAlertTitle(clientNotificationTitle);
    },
    [numberWaiting],
  );

  const screenreaderLabel = useMemo(() => {
    const waitingClients = numberWaiting
      ? `, ${numberWaiting} client${numberWaiting > 1 ? "s" : ""} waiting`
      : "";
    const unreadMessages = numberUnreadMessages
      ? `, ${numberUnreadMessages} unread message${
          numberUnreadMessages > 1 ? "s" : ""
        }`
      : "";
    return `${ariaLabel}${waitingClients}${unreadMessages}`;
  }, [ariaLabel, numberUnreadMessages, numberWaiting]);

  return (
    <DrawerButton
      as={Button}
      // NOTE: This list overlaps with the drawer states controlled by the
      // `ClientInformationButton`. This is intentional and ensures that when
      // that button is not available because a provider is not in session, this
      // button can still be focused on drawer close instead.
      forDrawerStates={[
        DrawerState.CLIENTS,
        ...DRAWER_STATES_CONTROLLED_BY_CLIENT_INFO_BUTTON,
      ]}
      aria-label={ariaLabel}
      aria-expanded={isClientsDrawerOpen}
      aria-controls={drawerId}
      data-testid="clients-drawer-button"
      use="secondary"
      className="hidden md:flex items-center gap-x-2"
      onClick={onClick}
    >
      <Icon aria-hidden icon={faUser} className="mr-2" />
      <span className="sr-only">{screenreaderLabel}</span>
      <span aria-hidden>Clients</span>
      {!!numberWaiting && (
        <Tag aria-hidden className="bg-lilac-100 lowercase">
          {numberWaiting} waiting
        </Tag>
      )}
      {!!numberUnreadMessages && (
        <Tag aria-hidden className="bg-lilac-100 lowercase">
          <Icon icon={faMessage} className="mr-1" /> {numberUnreadMessages}
        </Tag>
      )}
    </DrawerButton>
  );
}

export function ClientsDrawerButtonWrapper({ drawerId }: { drawerId: string }) {
  const [drawerState, setDrawerState] = useAtom(drawerStateAtom);
  const numberOfWaitingClients = useAtomValue(numWaitingVisitorsAtom);
  const numberOfUnreadMessages = useAtomValue(totalUnreadMessageCountAtom);
  const isInSession = !!useAtomValue(participantCountAtom);

  const onClick = (): void => {
    if (drawerState === DrawerState.CLIENTS) {
      setDrawerState(null);
      return;
    }
    toast.remove();
    setDrawerState(DrawerState.CLIENTS);
  };

  return (
    <>
      <ClientsDrawerIconButton
        drawerId={drawerId}
        drawerState={drawerState}
        notificationCount={numberOfWaitingClients}
        onClick={onClick}
        isHidden={isInSession}
      />
      <ClientsDrawerButton
        drawerId={drawerId}
        drawerState={drawerState}
        numberUnreadMessages={numberOfUnreadMessages}
        numberWaiting={numberOfWaitingClients}
        onClick={onClick}
      />
    </>
  );
}
