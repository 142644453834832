import { useCallback } from "react";
import { providerShortIdAtom } from "../state";
import { UserType } from "../../types";
import { useSendParticipantStateCallback as useSendParticipantStateCallback } from "../../twilio/messages/useSendParticipantStateCallback";
import { telehealthTranscriptionGate } from "../../statsig/gates";
import { useAtomCallback } from "jotai/utils";
import { useFeatureGate } from "@statsig/react-bindings";

export function useSendProviderParticipantStateCallback() {
  const getProviderShortId = useAtomCallback(
    useCallback((get) => get(providerShortIdAtom), []),
  );

  const { value: telehealthTranscriptionIsEnabled } = useFeatureGate(
    telehealthTranscriptionGate,
  );

  const sendParticipantState = useSendParticipantStateCallback();

  return useCallback(() => {
    if (!telehealthTranscriptionIsEnabled) {
      return;
    }
    sendParticipantState({
      entityId: getProviderShortId()!,
      entityType: UserType.PROVIDER,
    });
  }, [
    sendParticipantState,
    telehealthTranscriptionIsEnabled,
    getProviderShortId,
  ]);
}
