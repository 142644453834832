import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Navbar,
  PageContainer,
  PageProviders,
  PushDrawerContainer,
  StageContainer,
} from "../../components";
import { logger } from "../../datadog";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { useGetTelehealthSessionInfo } from "../../hooks";
import { useInactiveTabPerformanceOptimization } from "../../hooks/useInactiveTabPerformanceOptimization";
import { useOnInactiveMobileScreenDetected } from "../../hooks/useOnInactiveMobileScreenDetected";
import { useWaitingRoomTrackingEvent } from "../../hooks/useWaitingRoomTrackingEvent";
import { EventTypeEnum } from "../../segment/types";
import { Theme } from "../../state";
import { ExperimentLayers } from "../../statsig/experiments";
import {
  AudioVideoControlsWrapper as AudioVideoControls,
  useHandleDeviceChanges,
  useInitLocalTracks,
} from "../../twilio";
import { DeviceType } from "../../types";
import { deviceIsMobile, getDeviceType } from "../../utils";
import { AdditionalOptionsMenuWrapper as AdditionalOptionsMenu } from "../AdditionalOptionsMenu";
import { HelpModalWrapper as HelpModal } from "../HelpModal";
import { PushDrawerWrapper as PushDrawer } from "../PushDrawer";
import { SelfVideoThumbnail } from "../SelfVideoThumbnail";
import { ChatDrawerButtonWrapper as ChatDrawerButton } from "../chat/ChatDrawerButton";
import {
  VisitorDrawerState,
  visitorDrawerStateAtom,
  visitorIsHelpModalOpenAtom,
  visitorFormData,
  visitorMeasuresAtom,
} from "../state";
import { useInitTwilioSyncClient } from "../sync/useInitTwilioSyncClient";
import {
  BackgroundAnimationContainerWrapper as BackgroundAnimationContainer,
  useAnimationStateReducer,
} from "./background-animation";
import { ProviderInfoSectionWrapper as ProviderInfoSection } from "./ProviderInfoSection";
import { ProviderNotHereModalWrapper as ProviderNotHereModal } from "./ProviderNotHereModal";
import { RoomLoaderWrapper as RoomLoader } from "./RoomLoader";
import { SessionInfoSectionWrapper as SessionInfoSection } from "./SessionInfoSection";
import { useInitVisitorChatAccess, useWaitingRoomConversation } from "./chat";
import { useClearProviderNoShowTabTitleCallback } from "./useClearProviderNoShowTabTitle";
import { useResetUserStateCallback } from "./useResetUserStateCallback";
import { useUpdateTitleOnProviderPastNoShowThreshold } from "./useUpdateTitleOnProviderPastNoShowThreshold";
import { useUpdateVisitorPresenceInterval } from "./useUpdateVisitorPresenceInterval";
import { useWaitingRoomTimeout } from "./useWaitingRoomTimeout";
import { useWatchForTwilioRoomToken } from "./useWatchForTwilioRoomToken";
import { usePreSessionPromptState } from "./usePreSessionPromptState";
import { useShouldUseMICInfra } from "../../hooks/measures/useShouldUseMICInfra";
import { DEFAULT_WAITING_ROOM_PAGE_TITLE } from "./metadata";
import { setDefaultMeetingRoomFaviconAndTitle } from "../meeting-room/metadata";
import { useLayer } from "@statsig/react-bindings";

function PageEffects() {
  const [layerName, layerConfig] = ExperimentLayers.ClientWaitingRoom;
  const layer = useLayer(layerName, {
    disableExposureLog: true,
  });
  const shouldShowAVSetup = layer?.get<boolean>(
    layerConfig.ShouldShowAvSetup,
    false,
  );

  const resetUserState = useResetUserStateCallback();
  useUpdateVisitorPresenceInterval();
  useInitTwilioSyncClient();
  const { clearPreSessionPrompt } = usePreSessionPromptState();
  useWatchForTwilioRoomToken({
    onDone: () => {
      clearPreSessionPrompt();
      setDefaultMeetingRoomFaviconAndTitle();
    },
  });
  useInitLocalTracks(!shouldShowAVSetup);
  useHandleDeviceChanges();
  useWaitingRoomTimeout();

  useInitVisitorChatAccess();
  useWaitingRoomConversation();
  useOnInactiveMobileScreenDetected(() => {
    resetUserState();
    logger.info("Returned to intro room due to inactive screen");
  });
  useUpdateTitleOnProviderPastNoShowThreshold();
  useInactiveTabPerformanceOptimization();
  const { providerShortId, patientShortId } = useParams();

  useWaitingRoomTrackingEvent(
    EventTypeEnum.CLIENT_WAITING_ROOM,
    providerShortId,
    patientShortId,
  );

  return null;
}

const PUSH_DRAWER_ARIA_CONTROLS_ID = "waiting-room-push-drawer";

export function Page() {
  const [tabSwitchingDisabled, setTabSwitchingDisabled] = useState(false);

  const [isHelpModalOpen, setIsHelpModalOpen] = useAtom(
    visitorIsHelpModalOpenAtom,
  );

  const clearProviderNoShowTabTitle = useClearProviderNoShowTabTitleCallback();
  const { providerShortId, patientShortId } = useParams();
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);

  const appointment = data?.telehealthSessionInfo?.appointment;
  const [visitorDrawerState, setVisitorDrawerState] = useAtom(
    visitorDrawerStateAtom,
  );

  const visitorForms = useAtomValue(visitorFormData);
  const visitorMeasures = useAtomValue(visitorMeasuresAtom);
  const shouldUseMICInfra = useShouldUseMICInfra();

  const hasFormData = shouldUseMICInfra
    ? !!visitorMeasures?.length
    : !!visitorForms?.providerForms?.length;

  const { pause: pauseAnimation, play: playAnimation } =
    useAnimationStateReducer();

  const isMobile = deviceIsMobile();

  const openDrawer = useCallback(
    (drawer: VisitorDrawerState): void => {
      setVisitorDrawerState(drawer);
      // Pause the background animation when the drawer is opened.
      pauseAnimation();
    },
    [setVisitorDrawerState, pauseAnimation],
  );

  const closeDrawer = useCallback(() => {
    setVisitorDrawerState(null);
    playAnimation();
  }, [setVisitorDrawerState, playAnimation]);

  const toggleDrawerState = useCallback(
    (drawer: VisitorDrawerState): void => {
      if (visitorDrawerState === drawer) {
        closeDrawer();
        return;
      }
      openDrawer(drawer);
    },
    [visitorDrawerState, closeDrawer, openDrawer],
  );

  const toggleSettingsDrawer = useCallback(() => {
    toggleDrawerState(VisitorDrawerState.SETTINGS);
  }, [toggleDrawerState]);

  const openHelpModal = useCallback(() => {
    sendLoggingEvents(TrackingEvents.ANY_HELP_REQUEST);
    sendLoggingEvents(TrackingEvents.CLIENT_HELP_REQUEST);
    setIsHelpModalOpen(true);
    // Pause the background animation when the help modal is opened.
    pauseAnimation();
  }, [setIsHelpModalOpen, pauseAnimation]);

  useEffect(() => {
    // it's a logic leap here to assume that device type mobile means we are
    // changing the tab switching behavior, so please keep an eye on:
    // useOnInactiveMobileScreenDetected, specifically, "visibilitychange" event
    // handler
    setTabSwitchingDisabled(getDeviceType() === DeviceType.Mobile);
  }, []);

  return (
    <>
      <PageEffects />
      <PageProviders theme={Theme.LIGHT}>
        <HelpModal
          isOpen={isHelpModalOpen}
          onClose={() => {
            setIsHelpModalOpen(false);
            clearProviderNoShowTabTitle();
            playAnimation();
          }}
          shouldShowProviderNoShowSection
          appointment={appointment}
        />
        <ProviderNotHereModal
          onOpenModal={pauseAnimation}
          onCloseModal={playAnimation}
        />
        <PageContainer>
          <Navbar
            drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID}
            drawerState={visitorDrawerState}
            forSettingsButtonDrawerState={VisitorDrawerState.SETTINGS}
            onClickSettingsButton={toggleSettingsDrawer}
            className="order-1"
          />
          <AudioVideoControls
            chatButtonComponent={
              <ChatDrawerButton
                drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID}
                onOpen={pauseAnimation}
                onClose={playAnimation}
                defaultTitle={DEFAULT_WAITING_ROOM_PAGE_TITLE}
              />
            }
            openHelpModal={openHelpModal}
            additionalOptionsComponent={
              <AdditionalOptionsMenu
                drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID}
                onClickSettingsButton={toggleSettingsDrawer}
                onOpenDrawer={pauseAnimation}
                onCloseDrawer={playAnimation}
              />
            }
            className="order-3"
          />
          <PushDrawerContainer
            id={PUSH_DRAWER_ARIA_CONTROLS_ID}
            drawer={<PushDrawer onCloseDrawer={playAnimation} />}
            // The push drawer must come _after_ the buttons that can trigger it
            // in the DOM. This is so that a keyboard user can tab forward from
            // the triggering button to the drawer. However, the drawer and
            // stage are meant to be visually positioned _before_ the control
            // buttons, so we reorder them within their flex container.
            className="order-2"
          >
            <StageContainer className="gap-y-0 @container">
              <BackgroundAnimationContainer>
                <ProviderInfoSection />
                <div className="flex flex-col flex-grow gap-y-8 justify-center items-center">
                  <SessionInfoSection
                    appointment={appointment}
                    openHelpModal={openHelpModal}
                    openDrawer={openDrawer}
                    showTabSwitchWarning={tabSwitchingDisabled}
                    hasForms={hasFormData}
                  />
                  {isMobile && (
                    <SelfVideoThumbnail
                      showExpandVideoToggle={false}
                      shouldShowHideSelf={false}
                      showSetPositionMenu={false}
                      className="relative self-center mt-2 mb-6 sm:mt-10 sm:mb-10"
                      videoClassName="min-h-[12rem] sm:min-h-[14rem] max-h-[18rem] min-w-[calc(100vw-2rem)] sm:min-w-[25rem] max-w-screen-sm"
                    />
                  )}
                </div>
              </BackgroundAnimationContainer>
              {!isMobile && (
                <SelfVideoThumbnail
                  showExpandVideoToggle
                  shouldShowHideSelf
                  showSetPositionMenu
                  className="absolute"
                />
              )}
            </StageContainer>
          </PushDrawerContainer>
          <RoomLoader />
        </PageContainer>
      </PageProviders>
    </>
  );
}
