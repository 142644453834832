import { faMicrophoneSlash } from "@fortawesome/pro-solid-svg-icons";
import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { twMerge } from "tailwind-merge";
import { useMemo } from "react";

export function NameBadge({
  name,
  className,
  isSelf,
  pronouns,
  audioMuted = false,
}: {
  name?: string;
  className?: string;
  isSelf: boolean;
  pronouns?: string[];
  audioMuted?: boolean;
}) {
  const pronounsText = useMemo(() => {
    if (!pronouns?.length) {
      return null;
    }
    return `(${pronouns[0]})`;
  }, [pronouns]);

  const screenReaderLabel = useMemo(() => {
    const nameText = name ?? "Participant";
    if (audioMuted) {
      return isSelf
        ? "You have your microphone muted."
        : pronounsText
          ? `${nameText} ${pronounsText} has their microphone muted.`
          : `${nameText} has their microphone muted.`;
    }
    return pronounsText ? `${nameText} ${pronounsText}` : nameText;
  }, [name, isSelf, pronounsText, audioMuted]);

  return (
    <div
      className={twMerge(
        "bg-lilac-700 inline-flex justify-center items-center rounded-tr-2xl h-10 px-4 max-w-full box-border",
        className,
      )}
      data-testid="name-badge"
      role="note"
    >
      <span className="sr-only" data-testid="name-badge.sr-label">
        {screenReaderLabel}
      </span>
      {audioMuted && (
        <Icon
          aria-hidden
          icon={faMicrophoneSlash}
          className={twMerge("text-neutral-100", name && "mr-2")}
          data-testid="audio-muted-badge"
        />
      )}
      {name && (
        <Text
          as="span"
          aria-hidden
          title={name}
          className="inline-block text-neutral-100 font-medium text-ellipsis overflow-hidden whitespace-nowrap fs-exclude"
          data-dd-privacy="mask"
          data-dd-action-name="Click on name badge"
          data-testid="name-badge.name"
        >
          {name}
          {pronounsText && (
            <span className="font-normal" data-testid="name-badge.pronouns">
              {" "}
              {pronounsText}
            </span>
          )}
        </Text>
      )}
    </div>
  );
}
