import { useCallback } from "react";
import {
  isRecordingAtom,
  patientConsentAtom,
} from "../../twilio/messages/state";
import { useAtomCallback } from "jotai/utils";
import { ConsentStatus, DataTrackMessage } from "../../twilio/types";
import {
  DataTrackUpdateStatus,
  getNewestDataTrackState,
  resolveCorrectConsentState,
} from "../../twilio/messages/utils";
import { DataTrackState } from "../../twilio/messages/types";
import { useClientTranscriptionResponseToastCallback } from "../session-recording/useClientTranscriptionResponseToastCallback";
import { useRecordAudioCallbacks } from "../session-recording/useRecordAudioCallbacks";
import { useSetAtom } from "jotai";
import { useSendProviderParticipantStateCallback } from "./useSendProviderParticipantStateCallback";
import { useRecordClientTranscriptionConsentMutation } from "../session-recording/useRecordClientTranscriptionConsent";
import { clientDirectConsentGate } from "../../statsig/gates";
import { Statsig } from "../../statsig/StatsigProvider";

function useHandleClientConsentCallback() {
  const getUserPatientConsent = useAtomCallback(
    useCallback((get) => get(patientConsentAtom), []),
  );
  const sendParticipantState = useSendProviderParticipantStateCallback();
  const popClientResponseTranscriptionToast =
    useClientTranscriptionResponseToastCallback();

  const setPatientConsent = useSetAtom(patientConsentAtom);
  const recordClientTranscriptionConsent =
    useRecordClientTranscriptionConsentMutation();

  return useCallback(
    async (receivedParticipantState: DataTrackState) => {
      const currentConsentState = getUserPatientConsent();
      const receivedConsentState = receivedParticipantState.patient.consent;
      const [correctConsentState, comparisonResult] =
        resolveCorrectConsentState({
          receivedState: receivedConsentState,
          userState: currentConsentState,
        });

      if (comparisonResult === DataTrackUpdateStatus.NO_UPDATE_NEEDED) {
        return;
      }

      setPatientConsent(correctConsentState);

      const receivedNewConsentValue =
        comparisonResult ===
        DataTrackUpdateStatus.VALUE_AND_LAST_UPDATED_CHANGED;

      if (!receivedNewConsentValue) {
        return;
      }
      /** If the patient has newly consented,
       * record the consent and tell the provider
       */
      if (correctConsentState.value === ConsentStatus.OPTED_IN) {
        if (!Statsig.checkGate(clientDirectConsentGate)) {
          await recordClientTranscriptionConsent(true);
        }
        popClientResponseTranscriptionToast(true);
      }
      /** If the patient has declined to consent,
       *  tell the provider
       */
      if (correctConsentState.value === ConsentStatus.SOFT_NO) {
        popClientResponseTranscriptionToast(false);
      }
      sendParticipantState();
    },
    [
      getUserPatientConsent,
      setPatientConsent,
      sendParticipantState,
      recordClientTranscriptionConsent,
      popClientResponseTranscriptionToast,
    ],
  );
}

function useHandleRecordingUpdateCallback() {
  const { startRecording, stopRecording } = useRecordAudioCallbacks();
  const getCurrentIsRecording = useAtomCallback(
    useCallback((get) => get(isRecordingAtom), []),
  );
  return useCallback(
    async (receivedState: DataTrackState) => {
      const [newestIsRecording, comparisonResult] = getNewestDataTrackState({
        receivedState: receivedState.isRecording,
        userState: getCurrentIsRecording(),
      });

      // Return early if no update is needed
      if (comparisonResult === DataTrackUpdateStatus.NO_UPDATE_NEEDED) {
        return;
      }

      if (newestIsRecording.value) {
        await startRecording({ lastUpdated: newestIsRecording.lastUpdated });
      } else {
        await stopRecording({ lastUpdated: newestIsRecording.lastUpdated });
      }
    },
    [startRecording, stopRecording, getCurrentIsRecording],
  );
}

export function useUpdateDataTrackStateCallback() {
  const handleRecordingUpdate = useHandleRecordingUpdateCallback();
  const handleClientConsentUpdate = useHandleClientConsentCallback();

  return useCallback(
    async (message: DataTrackMessage) => {
      const { participantState } = message;
      handleClientConsentUpdate(participantState);
      await handleRecordingUpdate(participantState);
    },
    [handleClientConsentUpdate, handleRecordingUpdate],
  );
}
