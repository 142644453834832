import {
  BackgroundAnimationOverlay,
  BackgroundAnimationOverlayProps,
} from "../../../components";
import {
  ActionType,
  useAnimationStateReducer,
} from "./useAnimationStateReducer";
import {
  AnimationStateToggleButtonWrapper,
  AnimationStateToggleButtonWrapperProps,
} from "./AnimationStateToggleButton";
import { FC } from "react";

type BackgroundAnimationContainerProps = {
  animationStateToggleButtonComponent: FC<AnimationStateToggleButtonWrapperProps>;
  backgroundAnimationOverlayComponent?: FC<BackgroundAnimationOverlayProps>;
  currentAnimationState: ActionType;
} & BackgroundAnimationOverlayProps;

export function BackgroundAnimationContainer({
  animationStateToggleButtonComponent: AnimationStateToggleButtonComponent,
  backgroundAnimationOverlayComponent:
    BackgroundAnimationOverlayComponent = BackgroundAnimationOverlay,
  children,
  currentAnimationState,
  ...overlayProps
}: BackgroundAnimationContainerProps) {
  return (
    <>
      <BackgroundAnimationOverlayComponent
        {...overlayProps}
        autoplay={currentAnimationState === "Play"}
        data-animation-state={currentAnimationState}
      />
      {/* "relative" to ensure the content is rendered above the animation */}
      <div className="relative flex flex-col overflow-y-auto h-full w-[calc(100%+0.2rem)]">
        {children}
        <AnimationStateToggleButtonComponent className="self-center sm:self-end mx-3 mb-3" />
      </div>
    </>
  );
}

export function BackgroundAnimationContainerWrapper(
  props: Pick<BackgroundAnimationContainerProps, "children">,
) {
  const { lottieRef, currentState } = useAnimationStateReducer();

  return (
    <BackgroundAnimationContainer
      {...props}
      animationStateToggleButtonComponent={AnimationStateToggleButtonWrapper}
      currentAnimationState={currentState}
      lottieRef={lottieRef}
    />
  );
}
