import { Button, ButtonSize, Icon } from "@grow-therapy-team/sprout-ui";
import { twMerge } from "tailwind-merge";
import {
  ACTION_ICONS,
  ActionType,
  getNextAction,
  useAnimationStateReducer,
} from "./useAnimationStateReducer";

export type AnimationStateToggleButtonProps = {
  className?: string;
  currentAnimationState: ActionType;
  onClick: (currentState: ActionType, nextState: ActionType) => void;
};

export function AnimationStateToggleButton({
  className,
  currentAnimationState,
  onClick,
}: AnimationStateToggleButtonProps): JSX.Element {
  const nextActionName = getNextAction(currentAnimationState);
  const nextActionIcon = ACTION_ICONS[nextActionName];
  return (
    <Button
      use="link"
      size={ButtonSize.Small}
      className={twMerge(
        "flex gap-x-2 items-center text-yellow-700 no-underline hover:brightness-75 hover:text-yellow-700",
        className,
      )}
      onClick={() => onClick(currentAnimationState, nextActionName)}
      aria-label={`${nextActionName} background animation`}
    >
      <Icon icon={nextActionIcon} />
      {nextActionName} animation
    </Button>
  );
}

export type AnimationStateToggleButtonWrapperProps = Pick<
  AnimationStateToggleButtonProps,
  "className"
>;

export function AnimationStateToggleButtonWrapper(
  props: AnimationStateToggleButtonWrapperProps,
) {
  const { currentState, toggle } = useAnimationStateReducer();

  return (
    <AnimationStateToggleButton
      {...props}
      currentAnimationState={currentState}
      onClick={toggle}
    />
  );
}
