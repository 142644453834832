import { DrawerState } from "../state";

export const CLIENT_INFO_ERROR_MESSAGE = `Sorry, we’re unable to pull up this client’s information right now.
If this issue persists, please reach out to support via the Provider
Portal.`;
export const CLIENT_HAS_LEFT_MESSAGE = "The client is no longer available";
export const CLIENT_DECLINED_TO_ANSWER = "Client declined to answer";

/**
 * These are drawer states that either:
 *  - Can be opened directly from the `ClientInformationButton`, or
 *  - Can be reached one or two steps away from a state that can be opened
 *    directly from the `ClientInformationButton`.
 *
 * When closing a drawer, if one of these states was the last active state, the
 * focus should return to the `ClientInformationButton`.
 */
export const DRAWER_STATES_CONTROLLED_BY_CLIENT_INFO_BUTTON: DrawerState[] = [
  DrawerState.CLIENT_INFORMATION,
  DrawerState.CLIENT_FORM_RESPONSE,
  DrawerState.EDIT_APPOINTMENT,
  DrawerState.ADD_APPOINTMENT,
  DrawerState.MEASURE_RESPONSES,
  DrawerState.PENDING_FORMS,
  DrawerState.PENDING_FORMS_COMPLETED,
  DrawerState.PENDING_MEASURES,
  DrawerState.PROGRESS_NOTES,
];

export const MEASURES_AND_FORMS_TITLE = "Measures & forms";
export const PROGRESS_TITLE = "Progress";
export const CLIENT_IDENTITY_TITLE = "Client identity & contact info";
