import {
  DrawerBody,
  DrawerHeader,
  TreatmentPlansContentConnected,
  Text,
  IconButton,
  ButtonSize,
  DRAWER_TITLE_ID,
  TreatmentPlansContentConnectedProps,
} from "@grow-therapy-team/sprout-ui";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { useSetAtom, useAtomValue } from "jotai";
import {
  drawerStateAtom,
  DrawerState,
  selectedClientInfoAtom,
} from "../../state";
import React from "react";

export const TreatmentPlansContentDrawer = ({
  onClickBackButton,
  patientShortId,
  treatmentPlansContentComponent: TreatmentPlansContent,
}: {
  onClickBackButton: () => void;
  patientShortId: string;
  treatmentPlansContentComponent: React.FC<TreatmentPlansContentConnectedProps>;
}) => {
  return (
    <>
      <DrawerHeader>
        <div className="flex items-center" data-testid="treatment-plans.header">
          {onClickBackButton && (
            <IconButton
              onClick={onClickBackButton}
              className="mr-4 w-8 h-8 flex justify-center items-center"
              size={ButtonSize.Small}
              aria-label="Go back"
              data-testid="treatment-plans.button.back"
              iconDefinition={faArrowLeft}
            />
          )}
          <Text variant="lg" className="font-medium" id={DRAWER_TITLE_ID}>
            Treatment Plans
          </Text>
        </div>
      </DrawerHeader>
      <DrawerBody>
        <div data-testid="client-info.treatment.treatment-plans-content">
          <TreatmentPlansContent patientShortId={patientShortId} />
        </div>
      </DrawerBody>
    </>
  );
};

export const TreatmentPlansContentDrawerWrapper = () => {
  const setDrawerState = useSetAtom(drawerStateAtom);

  const onClickBackButton = () => {
    setDrawerState(DrawerState.CLIENT_INFORMATION);
  };

  const patientShortId =
    useAtomValue(selectedClientInfoAtom)?.patientShortId ?? "";

  return (
    <TreatmentPlansContentDrawer
      onClickBackButton={onClickBackButton}
      patientShortId={patientShortId}
      treatmentPlansContentComponent={TreatmentPlansContentConnected}
    />
  );
};
