import { DEFAULT_APP_TITLE } from "../../config";
import { setDefaultFavicon, setTitle } from "../../metadata";

export const DEFAULT_MEETING_ROOM_PAGE_TITLE = `${DEFAULT_APP_TITLE} - Meeting Room`;

/**
 * Sets the default title for the meeting room
 */
export function setDefaultMeetingRoomTitle() {
  setTitle(DEFAULT_MEETING_ROOM_PAGE_TITLE);
}

/**
 * Sets the default favicon and title for the meeting room
 */
export function setDefaultMeetingRoomFaviconAndTitle() {
  setDefaultFavicon();
  setDefaultMeetingRoomTitle();
}
