import { useCallback } from "react";
import { useUnpublishLocalTrackCallback } from "./useUnpublishLocalTrackCallback";
import { usePublishLocalTrackCallback } from "./usePublishLocalTrackCallback";
import { useStoredAudioVideoSettings } from "../useStoredAudioVideoSettings";
import {
  QualityMode,
  compareQualityMode,
  consolidateQualityMode,
  getStoredAudioVideoSettings,
  trackOptsByQualityMode,
} from "../config";
import {
  localVideoTrackAtom,
  shouldSuggestPerformanceModeAtom,
  videoQualityAtom,
} from "../state";
import { useAtomCallback } from "jotai/utils";
import { getDeviceType } from "../../utils";
import { TrackSource } from "../types";
import { logger } from "../../datadog/logger";
import { CallbackError, CallbackSuccess } from "../../types";
import { LocalVideoTrack } from "twilio-video";
import { useSetAtom } from "jotai";
import { Mutex } from "async-mutex";
import { addProcessor, getCurrentProcessor } from "../processors";

export enum ChangeVideoQualityStatusCode {
  MISSING_TRACK = "missing_track",
  FAILED_TO_REQUIRE_TRACK = "failed_to_require_track",
  ERROR = "error",
  CHANGED = "changed",
  NOOP = "noop",
}

type ChangeVideoQualityCallbackResponse =
  | CallbackSuccess<LocalVideoTrack | null, ChangeVideoQualityStatusCode>
  | CallbackError<ChangeVideoQualityStatusCode>;

type GetMaxVideoQualityOpts = {
  suggestedPerformanceModeConstraint?: ConstraintMode;
  targetQuality?: QualityMode;
};

export enum QualityPersistanceType {
  STORAGE_AND_MEMORY = "storage_and_memory",
  MEMORY = "memory",
  NONE = "none",
}

export enum ConstraintMode {
  AUTO = "auto",
  ALWAYS = "always",
  NEVER = "never",
}

const trackQualityMutex = new Mutex();

/**
 * Returns a function that changes the video quality the local video track, and
 * saves the new quality mode.
 */
export function useChangeVideoQualityCallback() {
  const publishLocalVideoTrack = usePublishLocalTrackCallback("video");
  const unpublishLocalVideoTrack = useUnpublishLocalTrackCallback("video");
  const { setStoredAudioVideoSettings } = useStoredAudioVideoSettings();
  const getLocalVideoTrack = useAtomCallback(
    useCallback((get) => get(localVideoTrackAtom).track, []),
  );
  const getLocalVideoTrackState = useAtomCallback(
    useCallback((get) => get(localVideoTrackAtom).state, []),
  );
  const setVideoQuality = useSetAtom(videoQualityAtom);
  const getCurrentVideoQuality = useAtomCallback(
    useCallback((get) => get(videoQualityAtom), []),
  );

  return useCallback(
    async (
      qualityMode: QualityMode,
      opts?: {
        persistanceType?: QualityPersistanceType;
      },
    ): Promise<ChangeVideoQualityCallbackResponse> => {
      const { persistanceType = QualityPersistanceType.STORAGE_AND_MEMORY } =
        opts ?? {};

      const checkShouldChangeQuality = ():
        | { shouldChange: true; response: LocalVideoTrack }
        | {
            shouldChange: false;
            response: ChangeVideoQualityCallbackResponse;
          } => {
        if (qualityMode === getCurrentVideoQuality()) {
          return {
            shouldChange: false,
            response: {
              status: "success",
              code: ChangeVideoQualityStatusCode.NOOP,
              value: null,
            },
          };
        }

        const track = getLocalVideoTrack();
        if (!track)
          return {
            shouldChange: false,
            response: {
              status: "error",
              code: ChangeVideoQualityStatusCode.MISSING_TRACK,
            },
          };

        return {
          shouldChange: true,
          response: track,
        };
      };

      const { shouldChange, response } = checkShouldChangeQuality();
      if (!shouldChange) return response;

      const releaseMutex = await trackQualityMutex.acquire();
      try {
        const { shouldChange, response } = checkShouldChangeQuality();
        if (!shouldChange) return response;
        let track: LocalVideoTrack | undefined = response;

        if (qualityMode === getCurrentVideoQuality()) {
          return {
            status: "success",
            code: ChangeVideoQualityStatusCode.NOOP,
            value: null,
          };
        }

        const deviceId = track.mediaStreamTrack.getSettings().deviceId;
        const trackOptions = {
          ...trackOptsByQualityMode[qualityMode][getDeviceType()].video[
            TrackSource.Camera
          ],
          deviceId: {
            exact: deviceId,
          },
        };
        const trackState = getLocalVideoTrackState();
        if (
          !(await unpublishLocalVideoTrack({ shouldReleaseTrack: true })) ||
          !(track = (await publishLocalVideoTrack({
            trackOptions,
            trackState,
          })) as LocalVideoTrack | undefined)
        ) {
          logger.error("Unable to change video quality mode", {
            qualityMode,
            trackOptions,
          });
          return {
            status: "error",
            code: ChangeVideoQualityStatusCode.ERROR,
          };
        }

        // restore previous processing
        const processor = getCurrentProcessor();
        if (processor) {
          addProcessor(track, processor);
        }

        // store the new quality mode
        if (
          persistanceType === QualityPersistanceType.STORAGE_AND_MEMORY ||
          persistanceType === QualityPersistanceType.MEMORY
        ) {
          setVideoQuality(qualityMode);
        }
        const storedQualityMode = consolidateQualityMode(qualityMode);
        if (persistanceType === QualityPersistanceType.STORAGE_AND_MEMORY) {
          setStoredAudioVideoSettings((settings) => ({
            ...settings,
            videoQualityMode: storedQualityMode,
          }));
        }

        return {
          status: "success",
          code: ChangeVideoQualityStatusCode.CHANGED,
          value: track,
        };
      } catch (e) {
        const error = e as Error;
        logger.error(
          "Unable to change video quality mode",
          {
            qualityMode,
          },
          error as Error,
        );
        return {
          status: "error",
          code: ChangeVideoQualityStatusCode.ERROR,
          value: error,
        };
      } finally {
        releaseMutex();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

export function useDegradeVideoQualityCallback() {
  const changeVideoQuality = useChangeVideoQualityCallback();
  const getMaxVideoQuality = useGetVideoQualityLimitCallback();
  const getCurrentVideoQuality = useAtomCallback(
    useCallback((get) => get(videoQualityAtom), []),
  );

  return useCallback(
    async (
      opts?: Omit<GetMaxVideoQualityOpts, "shouldReturnLowerQuality">,
    ): Promise<ChangeVideoQualityCallbackResponse> => {
      return await changeVideoQuality(
        getMaxVideoQuality({
          targetQuality: getCurrentVideoQuality(),
          ...opts,
        }),
        {
          persistanceType: QualityPersistanceType.MEMORY,
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

export function useRevertVideoQualityCallback() {
  const changeVideoQuality = useChangeVideoQualityCallback();
  const getMaxVideoQuality = useGetVideoQualityLimitCallback();

  return useCallback(
    async (): Promise<ChangeVideoQualityCallbackResponse> => {
      return await changeVideoQuality(
        getMaxVideoQuality({
          targetQuality: getStoredAudioVideoSettings().videoQualityMode,
        }),
        {
          persistanceType: QualityPersistanceType.MEMORY,
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

/**
 * Returns a function that returns the maximum video quality that can be used,
 * based on the current state of the app. If `targetQuality` is provided, then
 * the function will return the lower of the two.
 *
 * Constraints:
 *  * Low quality:
 *    * If performance mode is suggested
 *  * High quality:
 *    * If none of the above constraints are met
 */
export function useGetVideoQualityLimitCallback() {
  const getShouldSuggestPerformanceMode = useAtomCallback(
    useCallback((get) => get(shouldSuggestPerformanceModeAtom), []),
  );

  return useCallback(
    (opts?: GetMaxVideoQualityOpts): QualityMode => {
      const {
        suggestedPerformanceModeConstraint = ConstraintMode.AUTO,
        targetQuality,
      } = opts ?? {};

      const shouldSuggestPerformanceMode =
        suggestedPerformanceModeConstraint !== ConstraintMode.NEVER &&
        (suggestedPerformanceModeConstraint === ConstraintMode.ALWAYS ||
          getShouldSuggestPerformanceMode());

      let maxQuality = QualityMode.High;
      if (shouldSuggestPerformanceMode) {
        maxQuality = QualityMode.Low;
      }

      if (!targetQuality) {
        return maxQuality;
      }

      if (compareQualityMode(targetQuality, maxQuality) < 0) {
        return targetQuality;
      }

      return maxQuality;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

export function useVideoQualityCallbacks() {
  return {
    getVideoQualityLimit: useGetVideoQualityLimitCallback(),
    changeVideoQuality: useChangeVideoQualityCallback(),
    degradeVideoQuality: useDegradeVideoQualityCallback(),
    revertVideoQuality: useRevertVideoQualityCallback(),
  };
}
