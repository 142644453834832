import {
  StatsigOptions,
  StatsigProvider as BaseStatsigProvider,
  StatsigUser,
} from "@statsig/react-bindings";
import { StatsigClient } from "@statsig/js-client";
import { getCurrentHost, getEnvFromHostName } from "../utils";
import { datadogRum } from "@datadog/browser-rum";
import { createContext, useEffect, useState } from "react";
import { ChromeToolbarPlaybackControlsSingleton } from "../classes/ChromeToolbarPlaybackControlsSingleton";

export const StatsigLoadingContext = createContext<boolean>(false);
type StatsigProviderOptions = StatsigOptions & {
  gateEvaluationCallback?: (key: string, value: boolean) => void;
  api?: string;
};

const statsigOptions: StatsigProviderOptions = {
  environment: {
    tier: getEnvFromHostName(getCurrentHost()),
  },
  // https://docs.datadoghq.com/real_user_monitoring/guide/setup-feature-flag-data-collection/?tab=browser#statsig-integration
  gateEvaluationCallback(key: string, value: boolean) {
    datadogRum.addFeatureFlagEvaluation(key, value);
  },
  // https://docs.statsig.com/infrastructure/managed-proxy/
  api: "https://yjxpzuzkp2367lnlk2xoxcc56q0hrtww.lambda-url.ap-south-1.on.aws/v1",
};

const STATSIG_CLIENT_KEY = "client-y2YXcN60n7VU4EkzyZABzzKCDe6gCuQtb70uLgf3U1u";

export const Statsig = new StatsigClient(
  STATSIG_CLIENT_KEY,
  {},
  statsigOptions,
);

export type StatsigProviderProps = {
  user: StatsigUser;
  children: React.ReactNode;
};

/**
 * Callback function to be executed after Statsig client initialization is
 * successful.
 *
 * Useful for initializing non-React things that depend on Statsig values.
 */
function onStatsigInitializationSuccess() {
  ChromeToolbarPlaybackControlsSingleton.getInstance();
}

export function StatsigProvider({ user, children }: StatsigProviderProps) {
  const [isStatsigClientLoading, setIsStatsigClientLoading] = useState(true);
  useEffect(
    function initializeStatsigClient() {
      if (!user) return;
      async function initialize() {
        await Statsig.updateUserAsync(user);
        await Statsig.initializeAsync();
      }
      initialize()
        .then(onStatsigInitializationSuccess)
        .catch((error) => {
          throw new Error(`Failed to initialize Statsig client: ${error}`);
        })
        .finally(() => setIsStatsigClientLoading(false));
    },
    [user],
  );

  return (
    <StatsigLoadingContext.Provider value={isStatsigClientLoading}>
      <BaseStatsigProvider client={Statsig} user={user}>
        {children}
      </BaseStatsigProvider>
    </StatsigLoadingContext.Provider>
  );
}
