import { gql, useQuery } from "@apollo/client";
import { useAtomValue, useSetAtom } from "jotai";
import { visitorClientShortIdAtom, visitorMeasuresAtom } from "../state";
import { useParams } from "react-router-dom";
import { Server } from "../../apollo/types";
import {
  Maybe,
  MeasureSchemaFieldVisibleCondition,
  MeasureSchemaSectionVisibleCondition,
  MeasureSchemaType,
  MeasureResponseStatus,
} from "@grow-therapy-team/sprout-ui";
import { useShouldSaveProgressForMeasures } from "../../hooks/measures/useShouldSaveProgressForMeasures";

// TODO: Move this into the shared MICTypes file in Sprout
// Copied from the monolith generated response type
export type OutstandingMeasuresResponse = {
  outstandingMeasures?: Array<{
    __typename?: "OutstandingMeasureObject";
    clientUserAnsweredBefore?: Maybe<boolean>;
    schema: {
      __typename?: "MeasureSchemaObject";
      measureSchemaShortId: string;
      type: MeasureSchemaType;
      isProviderSpecific: boolean;
      sections?: Maybe<
        Array<{
          __typename?: "MeasureSchemaSectionObject";
          key: string;
          visibleConditionName?: Maybe<MeasureSchemaSectionVisibleCondition>;
          fields?: Maybe<
            Array<{
              __typename?: "MeasureSchemaFieldObject";
              key: string;
              visibleConditionName?: Maybe<MeasureSchemaFieldVisibleCondition>;
              options?: Array<{
                __typename?: "MeasureSchemaFieldOptionObject";
                score: number;
                key: string;
              }>;
            }>
          >;
        }>
      >;
    };
    providers?: Maybe<
      Array<{
        __typename?: "PublicProviderObject";
        id: string;
        shortId: string;
        thumbnailUrl: string;
        name?: Maybe<string>;
      }>
    >;
  }> | null;
  outstandingMeasureBundles?: Maybe<
    Array<{
      __typename?: "PublicMeasureBundleObject";
      id: string;
      measureBundleShortId: string;
    }>
  >;
};

export const GET_CLIENT_USER_PAPERWORK = gql`
  query GetOutstandingMeasures($clientUserShortId: ID!, $providerShortId: ID) {
    outstandingMeasures(
      clientUserShortId: $clientUserShortId
      providerShortId: $providerShortId
    ) {
      status
      schema {
        measureSchemaShortId
        type
        isProviderSpecific
        sections {
          key
          visibleConditionName
          fields {
            key
            visibleConditionName
            options {
              score
              key
            }
          }
        }
      }
      clientUserAnsweredBefore
      providers {
        id
        shortId
        thumbnailUrl
        name
      }
      measureResponse {
        ...DraftMeasureResponse_MeasureResponseFields
      }
    }
    outstandingMeasureBundles(clientUserShortId: $clientUserShortId) {
      id
      measureBundleShortId
    }
  }
`;

export function useGetClientUserPaperwork() {
  const clientUserShortId = useAtomValue(visitorClientShortIdAtom);
  const shouldSaveProgressForMeasures = useShouldSaveProgressForMeasures();
  const { providerShortId } = useParams() as { providerShortId: string };

  const setVisitorMeasures = useSetAtom(visitorMeasuresAtom);

  return useQuery<OutstandingMeasuresResponse>(GET_CLIENT_USER_PAPERWORK, {
    // providerShortId: while not strictly required, omitting will return _all_ measures
    // rather than the ones for a specific patient-provider relationship
    skip: !clientUserShortId || !providerShortId,
    variables: {
      clientUserShortId,
      providerShortId,
      ...(shouldSaveProgressForMeasures
        ? {
            filters: {
              statusIn: [
                MeasureResponseStatus.COMPLETED,
                MeasureResponseStatus.IN_PROGRESS,
                MeasureResponseStatus.NOT_STARTED,
              ],
              includeMeasureResponse: true,
            },
          }
        : {}),
    },
    context: { server: Server.MONOLITH, skipAuth: true },
    onCompleted(data) {
      setVisitorMeasures(data.outstandingMeasures || []);
    },
  });
}
