import { RecordingControl as BaseRecordingControl } from "../../twilio/audio-video-controls/RecordingControl";
import { RecordingControlMultipleParticipantsToast } from "../../twilio/audio-video-controls/RecordingControlMultipleParticipantsToast";
import { ConsentStatus } from "../../twilio/types";
import { useAtomValue, useSetAtom } from "jotai";
import { useParams } from "react-router-dom";
import { secondsToMilliseconds } from "date-fns";
import { ConsentModalWrapper as ConsentModal } from "./ConsentModal";
import { RecordingNoticeWrapper as RecordingNotice } from "./RecordingNotice";
import { Tooltip } from "@grow-therapy-team/sprout-ui";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import {
  isRecordingAtom,
  patientConsentAtom,
  providerConsentAtom,
} from "../../twilio/messages/state";
import { useSendVisitorParticipantStateCallback } from "../messages/useSendVisitorParticipantState";
import { useTrackEvent } from "../../segment/segment";
import { EventTypeEnum } from "../../segment/types";
import { UserType } from "../../types";
import { useGetTelehealthSessionInfo } from "../../hooks/useGetTelehealthSessionInfo";

import { useShouldDisableTranscriptionForMultipleParticipants } from "../../twilio/audio-video-controls/useShouldDisableTranscriptionForMultipleParticipants";
import { useRecordingControl } from "./useRecordingControl";

export function RecordingControl() {
  const [showRecordingNotice, setShowRecordingNotice] = useState(false);
  const [hasShownRecordingNotice, setHasShownRecordingNotice] = useState(false);
  const [
    hasShownMultipleParticipantsNotice,
    setHasShownMultipleParticipantsNotice,
  ] = useState(false);
  const { userClicked } = useTrackEvent();
  const { providerShortId, patientShortId } = useParams();

  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const appointmentShortId = data?.telehealthSessionInfo?.appointment?.shortId;
  const { isRecording, isVisible } = useRecordingControl();
  const setIsRecording = useSetAtom(isRecordingAtom);
  const patientConsentState = useAtomValue(patientConsentAtom);
  const patientConsent = patientConsentState.value;
  const providerConsent = useAtomValue(providerConsentAtom).value;

  const toggleRecording = (shouldRecord: boolean) => {
    setShowRecordingNotice(false);
    setIsRecording({ value: shouldRecord, lastUpdated: Date.now() });
    sendParticipantState();
  };
  const sendParticipantState = useSendVisitorParticipantStateCallback();
  const bothPartiesConsented =
    patientConsent === ConsentStatus.OPTED_IN &&
    providerConsent === ConsentStatus.OPTED_IN;

  useEffect(
    function notifyPatientWhenRecordingFirstStarts() {
      if (isRecording && !hasShownRecordingNotice) {
        setShowRecordingNotice(true);
        setHasShownRecordingNotice(true);
      }
    },
    [isRecording, hasShownRecordingNotice],
  );

  /**
   * The below code is to prevent sessions with multiple participants from surfacing
   * Transcription features due to AI quality issues identified in these types of sessions.
   * It is behind a feature flag and can be removed when we improve AI features for these sessions.
   */

  const shouldDisableTranscriptionForMultipleParticipants =
    useShouldDisableTranscriptionForMultipleParticipants();

  const shouldShowMultipleParticipantTranscriptionDisabledToast =
    shouldDisableTranscriptionForMultipleParticipants &&
    isRecording &&
    !hasShownMultipleParticipantsNotice;

  useEffect(() => {
    if (shouldShowMultipleParticipantTranscriptionDisabledToast) {
      toast.custom(RecordingControlMultipleParticipantsToast, {
        duration: secondsToMilliseconds(10),
        position: "bottom-center",
      });
      setHasShownMultipleParticipantsNotice(true);
    }
  }, [shouldShowMultipleParticipantTranscriptionDisabledToast]);

  if (!isVisible || shouldDisableTranscriptionForMultipleParticipants)
    return null;

  return (
    <>
      <ConsentModal onStartRecording={() => setShowRecordingNotice(false)} />
      {(bothPartiesConsented || isRecording) && (
        <Tooltip
          isOpen={showRecordingNotice}
          disabled={!showRecordingNotice}
          forceInteractive={false}
          clickable
          text={
            <RecordingNotice onClose={() => setShowRecordingNotice(false)} />
          }
          className="!bg-transparent opacity-100"
        >
          <BaseRecordingControl
            className="bg-lilac-700"
            isRecording={isRecording}
            onRecordingToggle={(shouldRecord: boolean) => {
              toggleRecording(shouldRecord);
              userClicked(EventTypeEnum.CLICK_RECORDING_TOGGLE, {
                appointmentShortId: appointmentShortId!,
                newRecordingStatus: shouldRecord ? "PLAY" : "PAUSE",
                entityType: UserType.CLIENT,
              });
            }}
            consentPending={false} // Clients don't see this until they've consented
          />
        </Tooltip>
      )}
    </>
  );
}
