import {
  Text,
  Menu,
  MenuToggleButton,
  MenuItem,
  Icon,
  MenuDivider,
} from "@grow-therapy-team/sprout-ui";
import { ThumbnailPosition } from "../twilio/state";
import {
  faArrowDownLeft,
  faArrowDownRight,
  faArrowsUpDownLeftRight,
  faArrowUpLeft,
  faArrowUpRight,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";

interface PositionAttributes {
  placement: "bottom-left" | "bottom-right";
  icon: IconDefinition;
  label: string;
  menuClassName?: string;
}

const POSITION_ATTRIBUTES_BY_POSITION: Record<
  ThumbnailPosition,
  PositionAttributes
> = {
  [ThumbnailPosition.TOP_RIGHT]: {
    placement: "bottom-left",
    icon: faArrowUpRight,
    label: "Top right corner",
  },
  [ThumbnailPosition.TOP_LEFT]: {
    placement: "bottom-right",
    icon: faArrowUpLeft,
    label: "Top left corner",
  },
  [ThumbnailPosition.BOTTOM_LEFT]: {
    placement: "bottom-right",
    icon: faArrowDownLeft,
    label: "Bottom left corner",
    menuClassName: "bottom-10",
  },
  [ThumbnailPosition.BOTTOM_RIGHT]: {
    placement: "bottom-left",
    icon: faArrowDownRight,
    label: "Bottom right corner",
    menuClassName: "bottom-10",
  },
};

export function SetPositionMenu({
  position,
  setPosition,
  isMobile,
}: {
  position: ThumbnailPosition;
  setPosition: (thumbnailPosition: ThumbnailPosition) => void;
  isMobile: boolean;
}) {
  const currentPosition = POSITION_ATTRIBUTES_BY_POSITION[position];
  const positionChoices = Object.entries(
    POSITION_ATTRIBUTES_BY_POSITION,
  ).filter(([thumbnailPosition, _]) => thumbnailPosition != position);

  return (
    <div className="bottom-2 right-2 absolute">
      <Menu
        placement={currentPosition.placement}
        buttonClassName="group/move-button opacity-0 group-hover/video-thumbnail:opacity-100 focus:opacity-100 px-1.5 h-8 w-8 hover:w-auto flex items-center justify-center shadow-border-none focus-visible:shadow-border-none bg-neutral-800 hover:bg-neutral-800 active:bg-neutral-800 text-neutral-300 active:text-neutral-300 outline-transparent focus-visible:outline-none focus-visible:outline-neutral-800 focus-visible:outline-offset-0 border-2 border-transparent focus-visible:border-lilac-400"
        toggleButton={
          <MenuToggleButton visibleCaret={false}>
            {!isMobile && (
              <span
                aria-hidden
                className="mr-1 font-normal hidden group-hover/move-button:inline-block"
              >
                Move video
              </span>
            )}
            <span className="sr-only">Change video position</span>
            <Icon aria-hidden icon={faArrowsUpDownLeftRight} className="h-4" />
          </MenuToggleButton>
        }
        menuClassName={currentPosition.menuClassName}
      >
        <Text as="span" className="px-4 pt-1" variant="sm" aria-hidden>
          Move video
        </Text>
        <MenuDivider />
        <>
          {positionChoices.map(([thumbnailPosition, positionAttributes]) => (
            <MenuItem
              key={thumbnailPosition}
              onClick={() =>
                setPosition(thumbnailPosition as ThumbnailPosition)
              }
              className="flex items-center gap-2"
            >
              <Icon
                aria-hidden
                icon={positionAttributes.icon}
                className="w-4 h-4"
              />
              <span className="sr-only">Move video to</span>
              {positionAttributes.label}
            </MenuItem>
          ))}
        </>
      </Menu>
    </div>
  );
}
