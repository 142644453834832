import { useParams } from "react-router-dom";
import {
  StatsigProvider as BaseStatsigProvider,
  StatsigProviderProps as BaseStatsigProviderProps,
} from "../statsig/StatsigProvider";
import { UserType } from "../types";
import { useAtomCallback } from "jotai/utils";
import { visitorClientShortIdAtom } from "./state";
import { loadSegment } from "../segment/segment";
import { useEffect, useCallback, useMemo } from "react";

export function StatsigProvider({
  children,
}: Pick<BaseStatsigProviderProps, "children">) {
  const { patientShortId } = useParams<{ patientShortId: string }>();

  // Callback used here to enforce that the clientShortId should be stable
  // before this component is rendered
  const clientShortId = useAtomCallback(
    useCallback((get) => get(visitorClientShortIdAtom), []),
  )();

  useEffect(() => {
    loadSegment(UserType.CLIENT, clientShortId);
  }, [clientShortId]);

  const user = useMemo(() => {
    return {
      userID: patientShortId,
      custom: {
        userType: UserType.CLIENT,
      },
    };
  }, [patientShortId]);

  return <BaseStatsigProvider user={user}>{children}</BaseStatsigProvider>;
}
