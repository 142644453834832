import { Text } from "@grow-therapy-team/sprout-ui";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

export function BackgroundButton({
  image,
  altText,
  text,
  isDisabled = false,
  isSelected,
  onClick,
  isLarge = false,
  isMirrored = true,
  color,
  hasBorder = false,
}: {
  image?: string;
  altText?: string;
  text?: string;
  isDisabled?: boolean;
  isSelected: boolean;
  onClick: () => void;
  isLarge?: boolean;
  isMirrored?: boolean;
  color?: string;
  hasBorder?: boolean;
}) {
  return (
    <button
      disabled={isDisabled}
      onClick={onClick}
      className={twMerge(
        classNames(
          "h-20 w-full rounded-2xl focus:outline-2 focus:outline-lilac-700 focus:outline focus:outline-offset-2",
          {
            "cursor-not-allowed": isDisabled,
            "outline-2 outline-lilac-700 outline outline-offset-2": isSelected,
            "outline-neutral-400": isDisabled,
            "border border-neutral-400": hasBorder,
            "h-24": isLarge,
            relative: !!text,
            "bg-neutral-900": !image && !color,
          },
          color,
        ),
      )}
    >
      {image && (
        <img
          src={image}
          alt={altText ?? ""}
          className={twMerge(
            classNames("rounded-2xl h-full object-cover w-full", {
              "h-24": isLarge,
              "scale-x-[-1]": isMirrored,
            }),
          )}
        />
      )}
      {text && (
        <Text
          as="span"
          variant="sm"
          className={twMerge(
            classNames("font-medium text-neutral-100", {
              "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2":
                !!image,
            }),
          )}
        >
          {text}
        </Text>
      )}
    </button>
  );
}
