import {
  Card,
  MeasureInsightTableConnected,
  type MeasureInsightTableConnectedProps,
} from "@grow-therapy-team/sprout-ui";
import { useAtomValue, useSetAtom } from "jotai";
import { forwardRef, useCallback } from "react";
import { useCaptureComponentView } from "../../hooks/useCaptureComponentView";
import { useTrackEvent } from "../../segment/segment";
import { EventTypeEnum, UserViewedParams } from "../../segment/types";
import { telehealthMeasureProgress } from "../../statsig/gates";
import {
  DrawerState,
  drawerStateAtom,
  providerShortIdAtom,
  selectedBundleAtom,
  selectedClientInfoAtom,
  selectedClientUserShortIdAtom,
} from "../state";
import { useGetPatientByShortId } from "../useGetPatientByShortId";
import { PROGRESS_TITLE } from "./constants";
import { useFeatureGate } from "@statsig/react-bindings";

type WrapperProps = Omit<
  Partial<MeasureInsightTableConnectedProps>,
  "clientUserShortId" | "providerShortId"
>;

export const ClientInformationProgress = forwardRef<
  HTMLDivElement,
  MeasureInsightTableConnectedProps
>((measureInsightTableProps, ref) => {
  return (
    <div className="flex flex-col gap-2" ref={ref}>
      <Card title={PROGRESS_TITLE}>
        <MeasureInsightTableConnected {...measureInsightTableProps} />
      </Card>
    </div>
  );
});
ClientInformationProgress.displayName = "ClientInformationProgress";

// statically defined because:
// 1) we only show the table view of measure progress
// 2) this component only renders in-session
const LOGGING_PROPERTIES: UserViewedParams<EventTypeEnum.MEASURE_PROGRESS_VIEWED> =
  { measureProgressView: "table", inSession: true };

export function ClientInformationProgressWrapper({
  isProvider = true,
  isShownWithinPaperworkFlow = false,
  shouldAbbreviateScoreCategoryLabels = true,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  ...passthroughProps
}: WrapperProps) {
  const clientUserShortId = useAtomValue(selectedClientUserShortIdAtom);
  const providerShortId = useAtomValue(providerShortIdAtom);
  const setDrawerState = useSetAtom(drawerStateAtom);
  const { value: showMeasureProgress } = useFeatureGate(
    telehealthMeasureProgress,
  );
  const setSelectedBundle = useSetAtom(selectedBundleAtom);

  const selectedClientInfo = useAtomValue(selectedClientInfoAtom);
  const patientShortId = selectedClientInfo?.patientShortId;
  // useGetPatientByShortId returns a ton of data, but we are likely to be
  // fetching it later anyway, so given that subsequent calls return cached
  // data, it shouldn't add too much overhead calling it here.
  // `showMeasureProgress` prevents the call from being made if the gate is
  // disabled.
  const { data } = useGetPatientByShortId(
    showMeasureProgress ? patientShortId : undefined,
  );
  const measureBundles = data?.patientChart?.measureBundles;

  // Segment events
  const { userClicked } = useTrackEvent();
  const { ref } = useCaptureComponentView({
    type: EventTypeEnum.MEASURE_PROGRESS_VIEWED,
    properties: LOGGING_PROPERTIES,
  });

  const handleMeasureResponseClick = useCallback(
    (measureResponseShortId: string) => {
      userClicked(EventTypeEnum.MEASURE_PROGRESS_CLICKED, LOGGING_PROPERTIES);

      if (!measureResponseShortId) return;

      const selectedMeasureBundle = measureBundles?.find((bundle) =>
        bundle.measureBundleItems.some(
          (bundleItem) =>
            bundleItem?.measureResponse?.measureResponseShortId ===
            measureResponseShortId,
        ),
      );

      if (!selectedMeasureBundle) return;

      // the MeasureBundleResponses component does not have a mechanism to
      // navigate to a specific item, it always shows the first item, so we
      // hack around that by setting the first item to be the one that was
      // clicked.
      // TODO: https://growtherapy.atlassian.net/browse/CARED-1693
      const selectedMeasureBundleWithClickedFirst = {
        ...selectedMeasureBundle,
        measureBundleItems: [
          // we use filter here instead of find because the spread operator will
          // ignore undefined values that give TypeScript fits.
          ...selectedMeasureBundle.measureBundleItems.filter(
            (item) =>
              item?.measureResponse?.measureResponseShortId ===
              measureResponseShortId,
          ),
          ...selectedMeasureBundle.measureBundleItems.filter(
            (item) =>
              item?.measureResponse?.measureResponseShortId !==
              measureResponseShortId,
          ),
        ],
      };

      setSelectedBundle(selectedMeasureBundleWithClickedFirst);
      setDrawerState(DrawerState.MEASURE_RESPONSES);
    },
    [measureBundles, setDrawerState, setSelectedBundle, userClicked],
  );

  if (!showMeasureProgress || !clientUserShortId || !providerShortId) {
    return null;
  }

  return (
    <ClientInformationProgress
      {...passthroughProps}
      clientUserShortId={clientUserShortId}
      providerShortId={providerShortId}
      isProvider={isProvider}
      isShownWithinPaperworkFlow={isShownWithinPaperworkFlow}
      shouldAbbreviateScoreCategoryLabels={shouldAbbreviateScoreCategoryLabels}
      timezone={timezone}
      onClick={measureBundles ? handleMeasureResponseClick : undefined}
      ref={ref}
    />
  );
}
