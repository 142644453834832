import {
  CreateLocalAudioTrackOptions,
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalParticipant,
  LocalDataTrack,
  LocalVideoTrack,
  createLocalAudioTrack as createLocalAudioTrack_,
  createLocalTracks as createLocalTracks_,
  createLocalVideoTrack as createLocalVideoTrack_,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";
import { deviceIsIOS, getDeviceType, isSafari } from "../utils";
import {
  QualityMode,
  trackOptsByQualityMode,
  getStoredAudioVideoSettings,
  TrackMetadata,
} from "./config";
import { DeviceType, LocalAVTrack, TrackSource } from "./types";
import { logger } from "../datadog/logger";
import { isDevMode } from "../config";

export const FAILED_TO_RESTART_TRACK_ERROR_MESSAGE = "Failed to restart track";
export const FAILED_TO_REAQUIRE_TRACK_ERROR_MESSAGE =
  "Failed to re-acquire the MediaStreamTrack";
export const IS_DEV_TRACK_SETTINGS_ENABLED_KEY =
  "is-dev-track-settings-enabled";
export const DEV_TRACK_SETTINGS_KEY = "dev-track-settings";

function getDevTrackSettings(): {
  audioSettings?: CreateLocalAudioTrackOptions;
  videoSettings?: CreateLocalTrackOptions;
} {
  if (!isDevMode) return {};
  try {
    const isDevConnectionSettingsEnabled = !!parseInt(
      localStorage.getItem(IS_DEV_TRACK_SETTINGS_ENABLED_KEY) ?? "0",
    );
    if (!isDevConnectionSettingsEnabled) return {};
    const rawSettings = localStorage.getItem(DEV_TRACK_SETTINGS_KEY);
    if (!rawSettings) return {};
    const settings = JSON.parse(rawSettings);
    logger.debug("Using dev track settings", settings);
    return settings;
  } catch (error) {
    logger.debug("Error parsing dev track settings", {}, error as Error);
    return {};
  }
}

export function isPermissionsDeniedError(error: Error) {
  return /(permission denied)|(not allowed)/i.test(error.toString());
}

// https://www.twilio.com/docs/video/noise-cancellation#limitations
export const isKrispNoiseCancellationSupported = !deviceIsIOS() && !isSafari();

export function getLocalAudioTrackOpts(): CreateLocalTrackOptions {
  const { inputAudioDeviceId: deviceId } = getStoredAudioVideoSettings();
  const noiseCancellationOpts = isKrispNoiseCancellationSupported
    ? {
        noiseCancellationOptions: {
          sdkAssetsPath: "/noise-cancellation",
          vendor: "krisp",
        },
      }
    : {};
  const deviceType = getDeviceType();
  return {
    deviceId,
    echoCancellation: true,
    ...trackOptsByQualityMode[QualityMode.High][deviceType].audio[
      TrackSource.Microphone
    ],
    ...noiseCancellationOpts,
    ...getDevTrackSettings().audioSettings,
  };
}

export async function enableNoiseCancellation(track: LocalAudioTrack) {
  if (!track.noiseCancellation || track.noiseCancellation.isEnabled) return;
  return await track.noiseCancellation.enable();
}

export async function disableNoiseCancellation(track: LocalAudioTrack) {
  if (!track.noiseCancellation || !track.noiseCancellation.isEnabled) return;
  return await track.noiseCancellation.disable();
}

export function getLocalVideoTrackOpts(
  opts?: Pick<TrackMetadata, "deviceType" | "qualityMode"> & {
    source?: TrackSource.Camera | TrackSource.Screen;
  },
): CreateLocalTrackOptions {
  const {
    inputVideoDeviceId: deviceId,
    videoQualityMode: storedVideoQualityMode,
  } = getStoredAudioVideoSettings();
  const qualityMode =
    opts?.qualityMode ?? storedVideoQualityMode ?? QualityMode.High;
  const source = opts?.source ?? TrackSource.Camera;
  const deviceType = opts?.deviceType ?? getDeviceType();
  return {
    deviceId,
    ...trackOptsByQualityMode[qualityMode][deviceType].video[source],
    ...getDevTrackSettings().videoSettings,
  };
}

export function createLocalVideoTrack(options?: CreateLocalTrackOptions) {
  return createLocalVideoTrack_({
    ...getLocalVideoTrackOpts(),
    ...options,
  });
}

export function createLocalAudioTrack(options?: CreateLocalTrackOptions) {
  return createLocalAudioTrack_({
    ...getLocalAudioTrackOpts(),
    ...options,
  });
}
export async function createLocalTracks() {
  const localDataTrack = new LocalDataTrack();
  return createLocalTracks_({
    audio: getLocalAudioTrackOpts(),
    video: getLocalVideoTrackOpts(),
  }).then((tracks) => {
    return [...tracks, localDataTrack];
  });
}

/**
 * Unmounts a device from the browser; can no longer reuse the same track after
 * calling this.
 */
export function releaseTrack(track: LocalVideoTrack | LocalAudioTrack) {
  if (!track.isStopped) {
    track.stop();
  }
}

/**
 * Disables a local track, and unpublishes it if a local participant is provided.
 */
export function unpublishLocalTrack(
  track: LocalAVTrack,
  localParticipant?: LocalParticipant,
) {
  track.disable();

  if (localParticipant) {
    localParticipant.unpublishTrack(track);
  }

  return track;
}

/**
 * Enables a local track, and publishes it if a local participant is provided.
 */
export async function publishLocalTrack(
  track: LocalAVTrack,
  localParticipant?: LocalParticipant,
  isEnabled = true,
) {
  if (isEnabled) {
    track.enable();
  } else {
    track.disable();
  }

  if (localParticipant && isEnabled) {
    await localParticipant.publishTrack(track);
  }

  return track;
}

export function getRemoteAudioElements() {
  return Array.from(
    document.getElementsByClassName("remote-audio"),
  ) as HTMLAudioElement[];
}

export async function getAvailableDevices() {
  const devices = await navigator.mediaDevices.enumerateDevices();

  return {
    audioInputDevices: devices.filter(
      (device) => device.kind === DeviceType.AUDIO_INPUT,
    ),
    videoInputDevices: devices.filter(
      (device) => device.kind === DeviceType.VIDEO_INPUT,
    ),
    audioOutputDevices: devices.filter(
      (device) => device.kind === DeviceType.AUDIO_OUTPUT,
    ),
  };
}

type DetachTrackParameters = {
  track: RemoteAudioTrack | RemoteVideoTrack;
  element: HTMLAudioElement | HTMLVideoElement;
};

export function detachRemoteTrack({ track, element }: DetachTrackParameters) {
  try {
    track.detach(element);
    element.srcObject = null;
  } catch (error) {
    logger.warn("Failed to detach audio track", {}, error as Error);
  }
}
