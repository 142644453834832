import { useAtomValue } from "jotai";
import { TranscriptionPill } from "../../twilio/TranscriptionPill";
import { ConsentStatus } from "../../twilio/types";
import {
  patientConsentAtom,
  providerConsentAtom,
} from "../../twilio/messages/state";
import { participantCountAtom } from "../../twilio";
import { useRecordingControl } from "./useRecordingControl";

export function TranscriptionPillWrapper() {
  const patientConsent = useAtomValue(patientConsentAtom).value;
  const providerConsent = useAtomValue(providerConsentAtom).value;
  const hasMultipleParticipants = useAtomValue(participantCountAtom) > 1;

  const { isRecording, isVisible } = useRecordingControl();

  const bothPartiesConsented =
    patientConsent === ConsentStatus.OPTED_IN &&
    providerConsent === ConsentStatus.OPTED_IN;

  if (
    !isVisible ||
    !(bothPartiesConsented || isRecording) ||
    hasMultipleParticipants
  ) {
    return null;
  }

  return (
    <TranscriptionPill
      isRecording={isRecording}
      patientConsent={patientConsent}
    />
  );
}
