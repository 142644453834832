import { setAlertTitle, setDefaultFavicon, setTitle } from "../../metadata";

export function setFaviconAndTitleBasedOnUnreadMessagesCount(
  unreadMessagesCount: number,
  defaultTitle: string,
) {
  if (unreadMessagesCount) {
    const unreadMessagesTitle = `(${unreadMessagesCount}) message${
      unreadMessagesCount > 1 ? "s" : ""
    }`;
    setAlertTitle(unreadMessagesTitle);
    return;
  }
  setDefaultFavicon();
  setTitle(defaultTitle);
}
