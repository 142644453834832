import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Appointment, Provider } from "../types";
import { useSetAtom } from "jotai";
import { sessionInfoAtom } from "../state";

export type GetTelehealthSessionInfoVariables = {
  providerShortId: string | undefined;
  patientShortId: string | undefined;
};

export type TelehealthSessionInfoData_TelehealthSessionInfo_Provider = Pick<
  Provider,
  "image" | "name" | "shortId" | "pronouns"
>;

export type TelehealthSessionInfoData_TelehealthSessionInfo_Appointment = Pick<
  Appointment,
  | "timeEnd"
  | "timeStart"
  | "shortId"
  | "clientUserShortId"
  | "hasProviderNoShowReport"
>;

export type TelehealthSessionInfoData = {
  telehealthSessionInfo: {
    provider: TelehealthSessionInfoData_TelehealthSessionInfo_Provider;
    appointment: TelehealthSessionInfoData_TelehealthSessionInfo_Appointment;
  };
};

export const GetTelehealthSessionInfoGql = gql`
  query GetTelehealthSessionInfo(
    $providerShortId: String!
    $patientShortId: String
  ) {
    telehealthSessionInfo(
      providerShortId: $providerShortId
      patientShortId: $patientShortId
    ) {
      provider {
        name
        shortId
        image
        pronouns
      }
      appointment {
        hasProviderNoShowReport
        timeStart
        clientUserShortId
        timeEnd
        shortId
      }
    }
  }
`;

export function useGetTelehealthSessionInfo(
  providerShortId: string | undefined,
  patientShortId: string | undefined,
) {
  const setSessionInfo = useSetAtom(sessionInfoAtom);
  return useQuery<TelehealthSessionInfoData, GetTelehealthSessionInfoVariables>(
    GetTelehealthSessionInfoGql,
    {
      variables: { providerShortId, patientShortId },
      skip: !providerShortId,
      context: { skipAuth: true },
      onCompleted(data) {
        setSessionInfo(data?.telehealthSessionInfo);
      },
    },
  );
}

export function useGetTelehealthSessionInfoCallback() {
  return useLazyQuery<
    TelehealthSessionInfoData,
    GetTelehealthSessionInfoVariables
  >(GetTelehealthSessionInfoGql, {
    context: { skipAuth: true },
  });
}
