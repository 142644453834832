import { atom } from "jotai";
import {
  FormDataByPatientShortId,
  inSessionPatientInformationAtom,
  providerAtom,
  selectedClientInfoAtom,
} from "../state";
import { isFunction, partition, pick } from "remeda";
import {
  combineFormData,
  combineFormDataV2,
  groupByFormTitle,
  removeIntakeForm,
  sortFormsByFormType,
} from "../../components/forms/utils";
import { createDerivedWritableAtom } from "../../utils";
import { ExperimentLayers } from "../../statsig/experiments";
import { atomFamily } from "jotai/utils";
import { isToday } from "date-fns";
import {
  FormDataGroup,
  FormValuesByFormTitle,
} from "../../components/forms/types";
import { Statsig } from "../../statsig/StatsigProvider";

export const formDataByPatientShortIdAtom = atom(
  (get) => get(providerAtom).formDataByPatientShortId,
  (
    _get,
    set,
    valueOrFunction:
      | FormDataByPatientShortId
      | ((prev: FormDataByPatientShortId) => FormDataByPatientShortId),
  ) => {
    set(providerAtom, (prev) => ({
      ...prev,
      formDataByPatientShortId: isFunction(valueOrFunction)
        ? valueOrFunction(prev.formDataByPatientShortId)
        : valueOrFunction,
    }));
  },
);

export const hasAnyNewFormsAtom = atom((get) => {
  const allForms = Object.values(get(formDataByPatientShortIdAtom));
  return !!allForms.some((group) =>
    group.formResponses?.some((f) => isToday(new Date(f.createdAt))),
  );
});

export const hasNewFormsAtomFamily = atomFamily((patientShortId: string) => {
  return atom((get) => {
    const formData = get(formDataByPatientShortIdAtom)[patientShortId];
    return !!formData?.formResponses?.some((f) =>
      isToday(new Date(f.createdAt)),
    );
  });
});

export const formDataForSelectedClientAtom = atom(
  (get) => {
    const patientShortId = get(selectedClientInfoAtom)?.patientShortId;
    if (!patientShortId) return;
    return get(formDataByPatientShortIdAtom)[patientShortId];
  },
  (
    get,
    set,
    valueOrFunction: FormDataGroup | ((prev?: FormDataGroup) => FormDataGroup),
  ) => {
    const patientShortId = get(selectedClientInfoAtom)?.patientShortId;
    if (!patientShortId) return;
    const formDataForPatient = get(formDataByPatientShortIdAtom)[
      patientShortId
    ];
    set(formDataByPatientShortIdAtom, (prev) => ({
      ...prev,
      [patientShortId]: isFunction(valueOrFunction)
        ? valueOrFunction(formDataForPatient)
        : valueOrFunction,
    }));
  },
);

export const providerFormsForSelectedClientAtom = atom(
  (get) => get(formDataForSelectedClientAtom)?.providerForms,
);

export const formResponsesForSelectedClientAtom = atom(
  (get) => get(formDataForSelectedClientAtom)?.formResponses,
);

export const formValuesForSelectedClientAtom = atom(
  (get) => get(formDataForSelectedClientAtom)?.formValuesByTitle,
  (
    _get,
    set,
    valueOrFunction:
      | FormValuesByFormTitle
      | ((prev?: FormValuesByFormTitle) => FormValuesByFormTitle),
  ) => {
    set(formDataForSelectedClientAtom, (prev) => {
      const prevFormValuesByTitle = prev?.formValuesByTitle;
      return {
        ...prev,
        formValuesByTitle: isFunction(valueOrFunction)
          ? valueOrFunction(prevFormValuesByTitle)
          : valueOrFunction,
      };
    });
  },
);

export const groupedFormsByTitleForSelectedClientAtom = atom((get) => {
  const { providerForms, formResponses } =
    get(formDataForSelectedClientAtom) ?? {};
  if (!providerForms || !formResponses) return;
  const [layerName, layerConfig] = ExperimentLayers.Measures;
  const layer = Statsig.getLayer(layerName);
  if (layer?.get<boolean>(layerConfig.ShouldShowProviderMeasures, false)) {
    return combineFormDataV2(formResponses, providerForms);
  } else {
    return groupByFormTitle(combineFormData(formResponses, providerForms));
  }
});

export const groupedFormsByTitleForSelectedClientByCompletionStatusAtom = atom(
  (get) => {
    const groupedFormsByTitle = get(groupedFormsByTitleForSelectedClientAtom);
    if (!groupedFormsByTitle) return;
    const formTitles = Object.keys(groupedFormsByTitle);
    const [completedFormTitles, pendingFormTitles] = partition(
      formTitles,
      (formTitle) => !!groupedFormsByTitle[formTitle][0].formResponseId,
    );
    return {
      completed: pick(groupedFormsByTitle, completedFormTitles),
      pending: pick(groupedFormsByTitle, pendingFormTitles),
    };
  },
);

export const completedFormsForSelectedClientAtom = atom((get) => {
  return get(groupedFormsByTitleForSelectedClientByCompletionStatusAtom)
    ?.completed;
});

export const pendingFormsForSelectedClientAtom = atom((get) => {
  const pendingFormsByTitle = get(
    groupedFormsByTitleForSelectedClientByCompletionStatusAtom,
  )?.pending;
  return pendingFormsByTitle && removeIntakeForm(pendingFormsByTitle);
});

export const sortedPendingFormsForSelectedClientAtom = atom((get) => {
  const pendingForms = get(pendingFormsForSelectedClientAtom);
  if (!pendingForms) return [];
  return sortFormsByFormType(Object.values(pendingForms).flat());
});

export const selectedFormTitleAtom = atom(
  (get) => get(providerAtom).selectedFormTitle,
  (_get, set, formTitle: string) => {
    set(providerAtom, (prev) => ({ ...prev, selectedFormTitle: formTitle }));
  },
);

export const selectedGroupedFormAtom = atom((get) => {
  const formTitle = get(selectedFormTitleAtom);
  const formattedFormResponses = get(groupedFormsByTitleForSelectedClientAtom);
  if (!formTitle || !formattedFormResponses) return;
  return formattedFormResponses[formTitle];
});

export const activeClientInformationTabAtom = createDerivedWritableAtom(
  providerAtom,
  "activeTab",
);

export const inSessionClientHasPendingFormsAtom = atom((get) => {
  const [layerName, layerConfig] = ExperimentLayers.Measures;
  const layer = Statsig.getLayer(layerName);
  const isFeatureEnabled = !!layer?.get<boolean>(
    layerConfig.ShouldShowProviderMeasures,
    false,
  );
  if (!isFeatureEnabled) return false;
  const patientShortId = get(inSessionPatientInformationAtom)?.patientShortId;
  if (!patientShortId) return false;
  const formDataByPatientShortId = get(formDataByPatientShortIdAtom);
  const { providerForms, formResponses } =
    formDataByPatientShortId[patientShortId] ?? {};
  if (!providerForms || !formResponses) return false;
  const combinedFormData = combineFormDataV2(formResponses, providerForms);
  return Object.values(removeIntakeForm(combinedFormData))
    .flat()
    .some((form) => !form.formResponseId);
});
