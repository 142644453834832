import { useCallback } from "react";
import { isSupported } from "@twilio/video-processors";
import { useStoredAudioVideoSettings } from "./useStoredAudioVideoSettings";
import { LocalVideoTrack } from "twilio-video";
import {
  addProcessor,
  blurProcessor,
  getBackgroundImage,
  loadProcessorModels,
  removeProcessor,
  virtualBackgroundProcessor,
} from "./processors";
import { BackgroundSettings, BackgroundType } from "./config";
import { logger } from "../datadog/logger";
import {
  LocalTrackState,
  localVideoTrackAtom,
  hasBackgroundProcessorFailureAtom,
  localVideoTrackStateAtom,
} from "./state";
import { useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";

// a word of caution before using this callback to set a background: this was
// written to _initialize_ the video component, and therefore, calling it will
// set the internal state of "is enabled" to true. Be sure to check whether
// track.isEnabled is true before calling this function, or you may end up with
// a video track that is disabled, but our internal state suggesting otherwise.
export function useInitBackgroundProcessorsCallback() {
  const { setStoredAudioVideoSettings } = useStoredAudioVideoSettings();
  const setLocalVideoTrack = useSetAtom(localVideoTrackAtom);
  const setHasBackgroundProcessorFailure = useSetAtom(
    hasBackgroundProcessorFailureAtom,
  );
  const getLocalVideoTrack = useAtomCallback(
    useCallback((get) => get(localVideoTrackAtom).track, []),
  );
  const getLocalVideoTrackState = useAtomCallback(
    useCallback((get) => get(localVideoTrackStateAtom), []),
  );

  return useCallback(
    async function handleBackgroundChange(
      backgroundSettings: BackgroundSettings,
      givenVideoTrack?: LocalVideoTrack,
      opts?: {
        isSupportedOverride?: boolean;
        persistChanges?: boolean;
      },
    ) {
      const videoTrack = givenVideoTrack ?? getLocalVideoTrack();
      const isNotSupported = !(opts?.isSupportedOverride ?? isSupported);
      const shouldPersistChanges = opts?.persistChanges ?? true;
      const finalVideoTrackState = getLocalVideoTrackState();
      if (isNotSupported) {
        logger.error("Background processor(s) not supported");
        setHasBackgroundProcessorFailure(true);
        return;
      }
      if (!videoTrack) {
        setHasBackgroundProcessorFailure(true);
        logger.error("No video track supplied to processor.");
        return;
      }
      const { backgroundIndex, backgroundType } = backgroundSettings;

      const storeBackgroundSettings = () => {
        setStoredAudioVideoSettings((previousValue) => ({
          ...previousValue,
          ...backgroundSettings,
        }));
      };

      const addBackground = async () => {
        if (!videoTrack) return; // this is to appease TS; we already do this check above

        switch (backgroundType) {
          case BackgroundType.BLUR:
            if (!blurProcessor) break;
            addProcessor(videoTrack, blurProcessor);
            break;
          case BackgroundType.IMAGE:
            if (!virtualBackgroundProcessor) break;
            virtualBackgroundProcessor.backgroundImage =
              await getBackgroundImage(backgroundIndex ?? 0);
            addProcessor(videoTrack, virtualBackgroundProcessor);
            break;
        }

        if (shouldPersistChanges) storeBackgroundSettings();
      };

      const removeBackground = async () => {
        if (!videoTrack) return; // this is to appease TS; we already do this check above
        removeProcessor(videoTrack);

        if (shouldPersistChanges) storeBackgroundSettings();
      };

      try {
        setLocalVideoTrack((prev) => ({
          ...prev,
          state: LocalTrackState.LOADING,
        }));

        await loadProcessorModels();

        const hasBackground =
          backgroundType && backgroundType !== BackgroundType.NONE;

        if (hasBackground) {
          await addBackground();
        } else {
          await removeBackground();
        }
        setHasBackgroundProcessorFailure(false);
        return videoTrack;
      } catch (error) {
        logger.error(
          "Unable to init/add background processor(s)",
          {},
          error as Error,
        );
        setHasBackgroundProcessorFailure(true);
      } finally {
        setLocalVideoTrack((prev) => ({
          ...prev,
          state: finalVideoTrackState,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
