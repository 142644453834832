import { Announcement, AppState, Browser, DeviceType, Env } from "../../types";
import {
  checkIsAllowed,
  detectBrowser,
  getDeviceType,
  getEnvFromHostName,
} from "../../utils";
import { announcementConfig } from "../../statsig/gates";
import { useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import { AnnouncementToast } from "./AnnouncementToast";
import { getAppConfig } from "../../config";
import { logger } from "../../datadog/logger";
import { useDynamicConfig } from "@statsig/react-bindings";

export type CurrentState = {
  appState?: AppState;
  appVersion?: string;
  browser?: Browser;
  device?: DeviceType;
  environment?: Env;
  userId?: string;
  userType?: string;
};

export function checkShouldShowAnnouncement(
  {
    appState,
    appVersion,
    browser,
    device,
    environment,
    userId,
    userType,
  }: CurrentState,
  announcement: Announcement,
) {
  const {
    allowAppStates,
    allowAppVersions,
    allowUserIds,
    browsers,
    content,
    denyAppStates,
    denyAppVersions,
    denyUserIds,
    devices,
    environments,
    expirationTimestamp,
    userTypes,
  } = announcement;
  return (
    !!content &&
    checkIsAllowed(appState, { allowList: allowAppStates }) &&
    checkIsAllowed(appState, { denyList: denyAppStates }) &&
    checkIsAllowed(appVersion, { allowList: allowAppVersions }) &&
    checkIsAllowed(appVersion, { denyList: denyAppVersions }) &&
    checkIsAllowed(browser, { allowList: browsers }) &&
    checkIsAllowed(device, { allowList: devices }) &&
    checkIsAllowed(environment, { allowList: environments }) &&
    checkIsAllowed(userId, { allowList: allowUserIds }) &&
    checkIsAllowed(userId, { denyList: denyUserIds }) &&
    checkIsAllowed(userType, { allowList: userTypes }) &&
    (!expirationTimestamp || Date.now() < expirationTimestamp)
  );
}

function showAnnouncementToasts(announcements: Announcement[]) {
  return announcements.map((announcement) => {
    logger.info("Showing announcement banner", { announcement });
    return toast.custom(
      ({ id: toastId, className }) => {
        return (
          <AnnouncementToast
            contentMarkdown={announcement.content}
            onClose={() => {
              toast.remove(toastId);
            }}
            className={className}
          />
        );
      },
      {
        duration: Infinity,
        position: "top-center",
        className: "top-center-toast",
      },
    );
  });
}

/**
 * Hook to show important system announcements as toast banners. Please only use
 * this hook once per user experience e.g. once for client and once for
 * provider.
 */
export function useAnnouncementToast(givenCurrentState?: CurrentState) {
  const config = useDynamicConfig(announcementConfig);
  const announcements = (config?.value?.announcements ?? []) as Announcement[];
  const currentState = useMemo(
    () => ({
      appVersion: getAppConfig().version,
      browser: detectBrowser(),
      environment: getEnvFromHostName(location.hostname),
      device: getDeviceType(),
      ...givenCurrentState,
    }),
    [givenCurrentState],
  );
  const announcementsToShow = announcements.filter((announcement) =>
    checkShouldShowAnnouncement(currentState, announcement),
  );
  const shouldShowAnnouncement = !!announcementsToShow.length;

  useEffect(
    function handleAnnouncements() {
      if (!shouldShowAnnouncement) return;

      let shownToastIds: string[] = [];
      shownToastIds = showAnnouncementToasts(announcementsToShow);

      return function hideAnnouncements() {
        shownToastIds.forEach((toastId) => toast.remove(toastId));
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldShowAnnouncement],
  );
}
