import { ChatDrawerButton } from "../../components";
import { useEffect } from "react";
import { useChatDrawerButtonUtils } from "./useChatDrawerButtonUtils";
import { setFaviconAndTitleBasedOnUnreadMessagesCount } from "./utils";
import { PROVIDER_NO_SHOW_TITLE } from "../waiting-room/useClearProviderNoShowTabTitle";
import { useAtomValue } from "jotai";
import { VisitorDrawerState, visitorDrawerStateAtom } from "../state";
import { DEFAULT_APP_TITLE } from "../../config";

export function ChatDrawerButtonWrapper({
  drawerId,
  onOpen,
  onClose,
  defaultTitle = DEFAULT_APP_TITLE,
}: {
  drawerId: string;
  onOpen?: () => void;
  onClose?: () => void;
  defaultTitle?: string;
}) {
  const { unreadMessagesCount, toggleChatDrawer, isChatDrawerDisabled } =
    useChatDrawerButtonUtils({ onOpen, onClose });
  const drawerState = useAtomValue(visitorDrawerStateAtom);

  useEffect(
    function showUnreadChatNotificationInFaviconAndTitle() {
      // Provider no show title takes priority over chat notifications
      if (document.title === PROVIDER_NO_SHOW_TITLE) return;
      setFaviconAndTitleBasedOnUnreadMessagesCount(
        unreadMessagesCount,
        defaultTitle,
      );
    },
    [defaultTitle, unreadMessagesCount],
  );

  return (
    <ChatDrawerButton
      drawerId={drawerId}
      forDrawerStates={VisitorDrawerState.CHAT}
      drawerState={drawerState}
      onClick={toggleChatDrawer}
      disabled={isChatDrawerDisabled}
      messageCount={unreadMessagesCount}
    />
  );
}
