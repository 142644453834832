import { useCallback, useEffect } from "react";
import { useVisitorId } from "../useVisitorId";
import { useAtomValue, useSetAtom } from "jotai";
import {
  VisitorState,
  visitorStateAtom,
  activeConversationSidAtom,
  visitorAccessDocSidAtom,
  apiTokenAtom,
  sessionAppointmentShortIdAtom,
} from "../state";
import {
  deadlineAtom,
  sessionConversationSidAtom,
} from "./delayed-entry/state";
import smileRingtoneUri from "../../assets/audio/smile-ringtone.mp3?url";
import { debounce } from "../../utils";
import { NOTIFICATION_VOLUME } from "../../config";
import { twilioRoomTokenAtom, twilioSyncClientAtom } from "../../twilio";
import { useConnectTwilioRoomCallback } from "../../twilio/useConnectTwilioRoomCallback";
import { logger } from "../../datadog/logger";
import { toast } from "react-hot-toast";
import { gracefullyPlayAudio } from "../../audio";
import { Toast, ToastVariant } from "../../components";
import { delayedEntryGate } from "../../statsig/gates";
import { Mutex } from "async-mutex";
import { transcriptionFeatureEnabledAtom } from "../session-recording/state";
import { useGetTelehealthSessionInfoCallback } from "../../hooks";
import { useParams } from "react-router-dom";
import { Statsig } from "../../statsig/StatsigProvider";

const LOAD_TIME_MS = 1000;
const DEBOUNCE_TIME_MS = 1000;

enum VisitorAccessDocEventType {
  GRANTED = "access-granted",
  DENIED = "access-denied",
}

type VisitorAccessDocData = {
  eventType: VisitorAccessDocEventType;
  token: string;
  /**
   * A token that can be used to communicate with the Telehealth API as a client
   * user
   */
  apiToken?: string;
  conversationSid?: string;
  deadline?: string;
  isTranscriptionFeatureEnabled?: boolean;
};

const playJoiningRoomSound = debounce(() => {
  const ringtone = new Audio(smileRingtoneUri);
  ringtone.volume = NOTIFICATION_VOLUME;
  gracefullyPlayAudio(ringtone);
}, DEBOUNCE_TIME_MS);

const showErrorToast = (message: string) => {
  toast.custom(
    ({ id: toastId }) => {
      return (
        <Toast
          variant={ToastVariant.Error}
          onClose={() => {
            toast.remove(toastId);
          }}
        >
          {message}
        </Toast>
      );
    },
    {
      duration: Infinity,
      position: "top-center",
    },
  );
};

type HookOptions = {
  onDone?: () => void;
};

/**
 * Gracefully fetches the session appointment short ID and sets it in Jotai
 */
function useSetSessionAppointmentShortIdCallback() {
  const { providerShortId } = useParams<{ providerShortId: string }>();
  const { patientShortId } = useParams<{ patientShortId: string }>();
  const setSessionAppointmentShortId = useSetAtom(
    sessionAppointmentShortIdAtom,
  );
  const [getTelehealthSessionInfo] = useGetTelehealthSessionInfoCallback();

  return useCallback(async () => {
    try {
      const { data } = await getTelehealthSessionInfo({
        variables: {
          patientShortId,
          providerShortId,
        },
      });
      setSessionAppointmentShortId(
        data?.telehealthSessionInfo?.appointment?.shortId,
      );
    } catch (error) {
      logger.error(
        "Failed to fetch/set appointment short ID for session",
        {
          patientShortId,
          providerShortId,
        },
        error as Error,
      );
    }
  }, [
    getTelehealthSessionInfo,
    patientShortId,
    providerShortId,
    setSessionAppointmentShortId,
  ]);
}

const twilioRoomMutex = new Mutex();
export function useTransitionToMeetingRoom() {
  const visitorUuid = useVisitorId();
  const setVisitorStateAtom = useSetAtom(visitorStateAtom);
  const setActiveConversationSid = useSetAtom(activeConversationSidAtom);
  const setDeadline = useSetAtom(deadlineAtom);
  const [connectTwilioRoom] = useConnectTwilioRoomCallback();

  return useCallback(
    (conversationSid?: string, { onDone }: HookOptions = {}) => {
      // if multiple calls to transition to the meeting room happen simulatenously
      // the first one "wins" and the rest can just no-op.
      if (twilioRoomMutex.isLocked()) return;

      twilioRoomMutex.runExclusive(async () => {
        playJoiningRoomSound();
        setVisitorStateAtom(VisitorState.LOADING);

        // effectively sleep, need to await this way so that setTimeout runs async
        // otherwise, fire-and-forget will release the mutex before this timeout has elapsed!
        await new Promise((resolve) => setTimeout(resolve, LOAD_TIME_MS));

        const room = await connectTwilioRoom();

        if (!room) {
          showErrorToast("There was an issue joining the meeting.");
          logger.error("Failed to join Twilio room", { visitorUuid });
          setVisitorStateAtom(VisitorState.WAITING);
          return;
        }
        if (room.participants.size < 1) {
          showErrorToast("Your provider may have left the room.");
          logger.error("Twilio room has less than 1 remote participant", {
            visitorUuid,
          });
          setVisitorStateAtom(VisitorState.WAITING);
          return;
        }
        setVisitorStateAtom(VisitorState.MEETING);
        setDeadline(null);
        if (conversationSid) {
          toast.remove();
          setActiveConversationSid(conversationSid);
        }

        onDone?.();
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connectTwilioRoom],
  );
}

export function useWatchForTwilioRoomToken({ onDone }: HookOptions) {
  const visitorUuid = useVisitorId();
  const syncClient = useAtomValue(twilioSyncClientAtom);
  const visitorAccessDocSid = useAtomValue(visitorAccessDocSidAtom);
  const setDeadline = useSetAtom(deadlineAtom);
  const setIsTranscriptionFeatureEnabled = useSetAtom(
    transcriptionFeatureEnabledAtom,
  );
  const transitionToMeetingRoom = useTransitionToMeetingRoom();
  const setTwilioRoomToken = useSetAtom(twilioRoomTokenAtom);
  const setApiToken = useSetAtom(apiTokenAtom);
  const setSessionConversationSid = useSetAtom(sessionConversationSidAtom);
  const setSessionAppointmentShortId =
    useSetSessionAppointmentShortIdCallback();

  useEffect(
    function watchForTwilioRoomToken() {
      (async () => {
        if (!syncClient) return;
        logger.info("Initializing visitor-access document", {
          docSid: visitorAccessDocSid,
        });
        const visitorAccessDoc = await syncClient.document(visitorAccessDocSid);
        logger.info("Initialized visitor-access document", {
          docSid: visitorAccessDocSid,
        });
        visitorAccessDoc.on(
          "updated",
          ({
            data: {
              eventType,
              token,
              apiToken,
              conversationSid,
              deadline,
              isTranscriptionFeatureEnabled,
            },
          }: {
            data: VisitorAccessDocData;
          }) => {
            logger.info("Received visitor-access document update", {
              hasToken: !!token,
              eventType,
              conversationSid,
              deadline,
            });
            if (deadline) {
              setDeadline(new Date(deadline));
            }
            setTwilioRoomToken(token);
            setApiToken(apiToken);
            setSessionConversationSid(conversationSid);
            setIsTranscriptionFeatureEnabled(!!isTranscriptionFeatureEnabled);
            switch (eventType) {
              case VisitorAccessDocEventType.GRANTED:
                if (!Statsig.checkGate(delayedEntryGate)) {
                  // This is not session blocking so fire and forget
                  setSessionAppointmentShortId();

                  transitionToMeetingRoom(conversationSid, { onDone });
                }
                return;
              case VisitorAccessDocEventType.DENIED:
                // TODO: Handle denied event type
                return;
              default:
                logger.error("Received an unknown visitor-access event type", {
                  eventType,
                  visitorAccessDocSid,
                });
                return;
            }
          },
        );
      })();
    },
    [
      visitorUuid,
      syncClient,
      visitorAccessDocSid,
      transitionToMeetingRoom,
      setDeadline,
      setSessionConversationSid,
      setTwilioRoomToken,
      setIsTranscriptionFeatureEnabled,
      onDone,
      setApiToken,
      setSessionAppointmentShortId,
    ],
  );
}
