import { useLocalStorage } from "usehooks-ts";

// Show the nudge at most 4 times
const MAX_COUNT = 4;

/*
 * Hook which holds state for the session summary nudge.
 * Exposes whether the nudge should still be shown and provides functions
 * to mark the nudge being shown or to disable the nudge altogether.
 */
export function useSessionSummaryNudgeCount() {
  const [value, setValue] = useLocalStorage(
    "has-seen-complete-session-summary-nudge",
    0,
  );
  return {
    countBelowMax: value < MAX_COUNT,
    increment: () => setValue(value + 1),
    disable: () => setValue(MAX_COUNT),
  };
}
