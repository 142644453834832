import { toast } from "react-hot-toast";
import { EndSessionButton } from "../components";
import { TrackingEvents, sendLoggingEvents } from "../events";
import { useCompleteTwilioSessionCallback } from "./useCompleteTwilioSessionCallback";
import { SessionCompletedToastWrapper as SessionCompletedToast } from "./SessionCompletedToast";
import { hasRecordingAtom } from "./session-recording/state";
import { useShouldDisableTranscriptionForMultipleParticipants } from "../twilio/audio-video-controls/useShouldDisableTranscriptionForMultipleParticipants";
import { useSetAtom } from "jotai";
import { useSessionSummaryNudgeCount } from "./useSessionSummaryNudgeCount";

export function CompleteSessionButton() {
  /**
   * The shouldDisableTranscription hook needs to run before useCompleteTwilioSessionCallback here
   * because the latter hook will clean up all participants. This would cause it to always
   * resolve to as non-multiparticipant session.
   **/
  const shouldDisableTranscription =
    useShouldDisableTranscriptionForMultipleParticipants();
  const completeSession = useCompleteTwilioSessionCallback();
  const setHasRecording = useSetAtom(hasRecordingAtom);
  const { disable: disableFutureSessionSummaryNudges } =
    useSessionSummaryNudgeCount();
  return (
    <EndSessionButton
      onClick={() => {
        sendLoggingEvents(TrackingEvents.PROVIDER_SESSION_COMPLETE);
        completeSession();
        disableFutureSessionSummaryNudges();
        toast.custom(
          ({ id }) => (
            <SessionCompletedToast
              className="bottom-center-toast"
              onClose={() => toast.remove(id)}
              onCleanup={() => setHasRecording(false)}
              shouldDisableTranscription={shouldDisableTranscription}
            />
          ),
          {
            position: "bottom-center",
          },
        );
      }}
      aria-label="Complete session"
    >
      Complete Session
    </EndSessionButton>
  );
}
