import { atom } from "jotai";
import {
  ParticipantIdsByConversationSid,
  chatParticipantsByIdAtom,
  conversationsBySidAtom,
  currentConversationSidAtom,
  participantIdsAtom,
  participantIdsByConversationSidAtom,
  participantsByIdAtom,
  twilioIdentityAtom,
  unreadMessageCountByConversationSidAtom,
} from "../twilio/state";
import {
  Provider,
  UserType,
  VisitorPresenceMap,
  VisitorPresence,
} from "../types";
import { createDerivedWritableAtom } from "../utils";
import { atomFamily } from "jotai/utils";
import { parseIdentity } from "../twilio/utils";
import { Participant as ChatParticipant } from "@twilio/conversations";
import { identity, pipe } from "remeda";
import { getSelectedClientInfo } from "./utils";
import { scheduledPatientInformationAtom } from "./schedule-preview/state";
import { NoteTypeEnum } from "./client-information/types";
import { AudioRecorder } from "../classes";
import { FormDataGroup } from "../components/forms/types";
import {
  MeasureBundleObject,
  TreatmentPlanStatus,
} from "@grow-therapy-team/sprout-ui";

export enum ProviderState {
  IDLE = "IDLE",
  COMPLETED = "COMPLETED",
}

export enum ClientInformationDrawerTab {
  INFORMATION = "information",
  TREATMENT = "treatment",
  APPOINTMENTS = "appointments",
}

export enum ParticipantStatus {
  IN_SESSION = "IN SESSION",
  WAITING = "WAITING",
  SCHEDULED = "SCHEDULED",
}

export enum DrawerState {
  CLIENT_INFORMATION = "CLIENT_INFORMATION",
  CLIENTS = "CLIENTS",
  SETTINGS = "SETTINGS",
  WAITING_ROOM_CHAT = "WAITING_ROOM_CHAT",
  PARTICIPANT_DISCONNECTED_CHAT = "PARTICIPANT_DISCONNECTED_CHAT",
  ALL_CLIENTS_CHAT_LIST = "ALL_CLIENTS_CHAT_LIST",
  SESSION_CHAT = "SESSION_CHAT",
  CLIENT_FORM_RESPONSE = "CLIENT_FORM_RESPONSE",
  PROGRESS_NOTES = "PROGRESS_NOTES",
  EDIT_APPOINTMENT = "EDIT_APPOINTMENT",
  PENDING_FORMS = "PENDING_FORMS",
  PENDING_MEASURES = "PENDING_MEASURES",
  MEASURE_RESPONSES = "MEASURE_RESPONSES",
  PENDING_FORMS_COMPLETED = "PENDING_FORMS_COMPLETED",
  ADD_APPOINTMENT = "ADD_APPOINTMENT",
  CSSRS_FORM = "CSSRS_FORM",
  TREATMENT_PLANS_CONTENT = "TREATMENT_PLANS_CONTENT",
}

export type ChatDrawerState =
  | DrawerState.WAITING_ROOM_CHAT
  | DrawerState.SESSION_CHAT;

export const CHAT_DRAWER_STATES = new Set([
  DrawerState.WAITING_ROOM_CHAT,
  DrawerState.SESSION_CHAT,
]);

export type SelectedVisitor = Omit<VisitorPresence, "timestamp">;

export type WaitingRoomConversationSidsByVisitorId = Record<string, string>;
export type DeadlinesByVisitorId = Record<string, Date>;
export type PatientName = {
  patientFirstName?: string | null;
  patientLastName?: string | null;
};
export type FormDataByPatientShortId = Record<string, FormDataGroup>;
export type ProviderAtom = {
  state: ProviderState;
  newSessionConfirmationData?: {
    visitorUuid: string;
    visitorName: string;
    patientShortId: string;
  } | null;
  joiningVisitorIds: Set<string>;
  visitorPresenceMapTwilioToken?: string;
  waitingRoomConversationSidsByVisitorId: WaitingRoomConversationSidsByVisitorId;
  deadlinesByVisitorId: DeadlinesByVisitorId;
  drawerState: DrawerState | null;
  sessionConversationSid?: string;
  userData?: Required<Provider>;
  inSessionPatientInformation?: SelectedVisitor;

  // selectedClientId represents the visitorId which is unique to a telehealth session
  selectedClientId?: string;
  // selectedClientUserShortId represents the user's clientShortId associated with their clientUser in the monolith
  selectedClientUserShortId?: string;

  formDataByPatientShortId: FormDataByPatientShortId;
  selectedClientMeasuresData: MeasureBundleObject[];
  selectedPatientPreferredName?: PatientName;
  selectedFormTitle?: string;
  activeTab: ClientInformationDrawerTab;
  audioRecordingEntities: {
    audioContext: AudioContext;
    audioDestinationNode: MediaStreamAudioDestinationNode;
    audioRecorder: AudioRecorder;
  } | null;
  selectedBundle?: MeasureBundleObject;
};

export const DEFAULT_PROVIDER_ATOM_VALUE = {
  state: ProviderState.IDLE,
  joiningVisitorIds: new Set<string>(),
  waitingRoomConversationSidsByVisitorId: {},
  deadlinesByVisitorId: {},
  drawerState: null,
  formDataByPatientShortId: {},
  activeTab: ClientInformationDrawerTab.INFORMATION,
  audioRecordingEntities: null,
  selectedClientMeasuresData: [],
};

export const treatmentPlanContentStatus = atom<TreatmentPlanStatus>(
  TreatmentPlanStatus.CURRENT_GOALS,
);

export const selectedVisitorNotesAtom = atom<{
  notes: string;
  patientId: number;
  noteType: NoteTypeEnum;
}>({ notes: "", patientId: 0, noteType: NoteTypeEnum.GROW_PROGRESS });

export const providerAtom = atom<ProviderAtom>(DEFAULT_PROVIDER_ATOM_VALUE);

export const selectedClientIdAtom = createDerivedWritableAtom(
  providerAtom,
  "selectedClientId",
);
export const selectedPatientPreferredNameAtom = createDerivedWritableAtom(
  providerAtom,
  "selectedPatientPreferredName",
);

export const selectedClientUserShortIdAtom = createDerivedWritableAtom(
  providerAtom,
  "selectedClientUserShortId",
);

export const selectedClientMeasuresData = createDerivedWritableAtom(
  providerAtom,
  "selectedClientMeasuresData",
);

export const selectedBundleAtom = createDerivedWritableAtom(
  providerAtom,
  "selectedBundle",
);

export const selectedClientInfoAtom = atom((get) => {
  const selectedClientId = get(selectedClientIdAtom);
  const waitingVisitors = get(waitingVisitorsAtom);
  const selectedPatientPreferredName = get(selectedPatientPreferredNameAtom);
  const inSessionParticipant = get(inSessionPatientInformationAtom);
  const selectedScheduledPatient = get(scheduledPatientInformationAtom);
  const participants = get(participantsByIdAtom);

  return getSelectedClientInfo(
    selectedClientId,
    waitingVisitors,
    participants,
    selectedPatientPreferredName,
    inSessionParticipant,
    selectedScheduledPatient,
  );
});

export const selectedVisitorNotesAtomData = atom(
  (get) => get(selectedVisitorNotesAtom),
  (_get, set, notes: string, patientId: number, noteType: NoteTypeEnum) => {
    set(selectedVisitorNotesAtom, (prev) => ({
      ...prev,
      notes: notes,
      patientId,
      noteType,
    }));
  },
);

export const userDataAtom = createDerivedWritableAtom(providerAtom, "userData");
export const providerShortIdAtom = atom((get) => {
  const userData = get(userDataAtom);
  return userData?.shortId;
});

export const providerStateAtom = createDerivedWritableAtom(
  providerAtom,
  "state",
);

export const drawerStateAtom = createDerivedWritableAtom(
  providerAtom,
  "drawerState",
);

// TODO: Move this into the overarching provider atom; create derived atom instead
export const visitorPresenceMapSidAtom = atom<string | undefined>(undefined);

// TODO: Move this into the overarching provider atom; create derived atom instead
export const visitorPresenceAtom = atom<VisitorPresenceMap>({});

export const waitingVisitorsAtom = atom<VisitorPresenceMap>((get) =>
  Object.entries(get(visitorPresenceAtom))
    .filter(([visitorId]) => !get(participantsByIdAtom)[visitorId])
    .reduce(
      (agg, [visitorId, visitor]) => ({ ...agg, [visitorId]: visitor }),
      {},
    ),
);

export const numWaitingVisitorsAtom = atom(
  (get) => Object.keys(get(waitingVisitorsAtom)).length,
);

export const pageAtom = atom<{ drawerIsOpen?: boolean }>({
  drawerIsOpen: false,
});

export const waitingRoomConversationSidsByVisitorIdAtom =
  createDerivedWritableAtom(
    providerAtom,
    "waitingRoomConversationSidsByVisitorId",
  );

export const waitingRoomConversationSidsByVisitorIdFamily = atomFamily(
  (visitorId: string) => {
    return atom(
      (get) => get(waitingRoomConversationSidsByVisitorIdAtom)[visitorId],
    );
  },
);

export const newSessionConfirmationDataAtom = createDerivedWritableAtom(
  providerAtom,
  "newSessionConfirmationData",
);

export const joiningVisitorIdsAtom = createDerivedWritableAtom(
  providerAtom,
  "joiningVisitorIds",
);

export const joiningVisitorIdFamily = atomFamily((visitorId: string) => {
  return atom((get) => get(joiningVisitorIdsAtom).has(visitorId));
});

export const visitorPresenceMapTwilioTokenAtom = createDerivedWritableAtom(
  providerAtom,
  "visitorPresenceMapTwilioToken",
);

export const totalUnreadWaitingRoomMessagesAtom = atom((get) =>
  Object.values(get(waitingRoomConversationSidsByVisitorIdAtom)).reduce(
    (agg, conversationSid) => {
      const conversationUnreadCount =
        get(unreadMessageCountByConversationSidAtom)[conversationSid] ?? 0;
      return agg + conversationUnreadCount;
    },
    0,
  ),
);

export const totalUnreadMessageCountAtom = atom(
  (get) =>
    get(sessionConversationUnreadMessageCount) +
    get(totalUnreadWaitingRoomMessagesAtom),
);

export const sessionConversationSidAtom = createDerivedWritableAtom(
  providerAtom,
  "sessionConversationSid",
);

export const sessionConversationReadyAtom = atom((get) => {
  // check if the conversation exists
  const sessionConversationSid = get(sessionConversationSidAtom);
  if (!sessionConversationSid) {
    return false;
  }

  // check if the conversation has any expected participants
  const expectedParticipants =
    get(participantIdsByConversationSidAtom)[sessionConversationSid] ??
    new Set();
  if (expectedParticipants.size === 0) {
    return false;
  }

  // otherwise ensure at least one expected conversation participant is in the room
  const sessionParticipants = new Set(get(participantIdsAtom));
  const providerIdentity = get(twilioIdentityAtom);
  return [...expectedParticipants]
    .filter((p) => p !== providerIdentity)
    .some((p) => sessionParticipants.has(p));
});

/**
 * Stores the patient information of the patient that the provider is currently
 * in a meeting with.
 *
 * Set after a provider admits the patient and cleared on disconnect.
 */
export const inSessionPatientInformationAtom = createDerivedWritableAtom(
  providerAtom,
  "inSessionPatientInformation",
);

export const sessionConversationAtom = atom((get) => {
  const conversationSid = get(sessionConversationSidAtom);
  if (!conversationSid) return;
  const conversationsBySid = get(conversationsBySidAtom);
  return conversationsBySid[conversationSid];
});

export const isChatDrawerOpenAtom = atom((get) => {
  const drawerState = get(providerAtom).drawerState;
  const currentConversationSid = get(currentConversationSidAtom);
  const waitingRoomConversationSidsByVisitorId = get(
    waitingRoomConversationSidsByVisitorIdAtom,
  );
  const isCurrentWaitingRoomConversation = Object.values(
    waitingRoomConversationSidsByVisitorId,
  ).some((sid) => sid === currentConversationSid);

  switch (drawerState) {
    case DrawerState.WAITING_ROOM_CHAT:
      return currentConversationSid && isCurrentWaitingRoomConversation;
    case DrawerState.SESSION_CHAT:
      return true;
    default:
      return false;
  }
});

export const sessionConversationUnreadMessageCount = atom((get) => {
  const conversationSid = get(sessionConversationSidAtom);
  if (!conversationSid) return 0;
  return get(unreadMessageCountByConversationSidAtom)[conversationSid] ?? 0;
});

export const visitorChatParticipantsByConversationIdAtom = atom((get) => {
  return Object.entries(get(participantIdsByConversationSidAtom)).reduce(
    function participantIdsToParticipants(
      agg,
      [conversationSid, participantIds]: [
        keyof ParticipantIdsByConversationSid,
        ParticipantIdsByConversationSid[keyof ParticipantIdsByConversationSid],
      ],
    ) {
      return {
        ...agg,
        [conversationSid]: pipe(
          Array.from(participantIds),
          function filterClientParticipantIds(participantIds) {
            return participantIds.filter(
              (participantId) =>
                parseIdentity(participantId).userType === UserType.CLIENT,
            );
          },
          function mapToParticipants(participantIds) {
            return participantIds.map(
              (participantId) => get(chatParticipantsByIdAtom)[participantId],
            );
          },
          function filterValidParticipants(participants) {
            return participants.filter(identity);
          },
        ),
      };
    },
    {} as Record<string, ChatParticipant[]>,
  );
});

export const allVisitorChatParticipantsAtom = atom((get) => {
  // consider return Object.values(get(visitorChatParticipantsByConversationIdAtom)).flat();
  return Object.values(get(visitorChatParticipantsByConversationIdAtom)).reduce(
    (agg, participants) => agg.concat(participants),
    [],
  );
});

export const isInSessionWithSelectedClientAtom = atom((get) => {
  const selectedPatientShortId = get(selectedClientInfoAtom)?.patientShortId;
  const inSessionPatientShortId = get(
    inSessionPatientInformationAtom,
  )?.patientShortId;

  if (!selectedPatientShortId || !inSessionPatientShortId) return false;

  return selectedPatientShortId === inSessionPatientShortId;
});
