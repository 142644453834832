import { NoteType, NoteResponseType, FieldTypeEnum } from "./types";
import { GetCurrentNoteSchemaResponse } from "../useGetCurrentNoteSchema";
import { groupBy } from "remeda";
import {
  TimelineAppointment,
  TimelineAppointmentEdge,
} from "../useGetUpcomingAppointments";
import { DrawerState } from "../state";
import { NudgeObject, NudgeSchemaTrigger } from "@grow-therapy-team/sprout-ui";
import { EventTypeEnum } from "../../segment/types";

export function formatNotes(
  notes: NoteResponseType,
  currentNoteSchema?: GetCurrentNoteSchemaResponse,
): Record<string, NoteType[]> {
  if (!currentNoteSchema || !notes) return {};
  const formattedNotes: Record<string, NoteType[]> = {};

  currentNoteSchema.currentNoteSchema.sections.forEach((section) => {
    formattedNotes[section.label] = [];

    section.fields.forEach((field) => {
      let answer: string | string[] | boolean = "";
      if (field.fieldType === FieldTypeEnum.CHECKBOX) {
        answer =
          field.name === "risk_issues" && notes[field.name] === false
            ? "No suicidal or homicidal ideation suspected or disclosed"
            : notes[field.name];
      } else if (field.fieldType === FieldTypeEnum.MULTISELECT) {
        answer = Array.isArray(notes[field.name]) ? notes[field.name] : [];
      } else {
        answer = notes[field.name] || answer;
      }

      let details: string[] = [];

      // Many questions have supplemental questions that are appended by _details.
      // It's easier to keep track of by storing these details in the same section object rather than treating it like a separate question
      const baseFieldName = field.name.replace(/_details$/, "");
      const isDetailsField = baseFieldName !== field.name;

      if (isDetailsField) {
        const fieldValue = notes[field.name];
        details = Array.isArray(fieldValue)
          ? fieldValue
          : ([fieldValue] as string[]).filter(Boolean);

        const baseFieldFormattedSection = formattedNotes[section.label].find(
          (sectionItem) => sectionItem.name === baseFieldName,
        );

        if (baseFieldFormattedSection) {
          baseFieldFormattedSection.details = details;
        }
      } else {
        const formattedSection: NoteType = {
          question: field.label,
          name: field.name,
          answer: answer,
          margin: field.margin,
          textVariant: field.textVariant,
          fieldType: field.fieldType as FieldTypeEnum,
          details: details,
        };
        formattedNotes[section.label].push(formattedSection);
      }
    });
  });

  return formattedNotes;
}

/** Groups upcoming appointments so that recurring appointments show only the next upcoming entry and sorts them in date order */
export function formatUpcomingAppointments(
  appointments: TimelineAppointmentEdge[] | undefined,
): TimelineAppointment[] {
  const groupedAppointments = appointments
    ? groupBy(
        appointments,
        (appointment) =>
          appointment?.node?.recurringAppointmentGroupingId ??
          appointment?.node?.id,
      )
    : {};
  const upcomingAppointments = Object.values(groupedAppointments).map(
    (appointmentGroup) => appointmentGroup[0].node,
  );
  return upcomingAppointments.sort((a, b) => {
    return new Date(a.timeStart).getTime() - new Date(b.timeStart).getTime();
  });
}

/**
 * Creates click handler functions for different nudge triggers.
 *
 * @param nudges - Array of nudge objects
 * @param setDrawerState - Function to update drawer state
 * @param providerDashboardReferralUri - URI for provider dashboard referral
 * @param userClicked - Event logging function
 * @param isInSessionWithSelectedClient - Flag indicating if in session with selected client
 * @returns Mapping of nudge triggers to their click handler functions
 */
export function createNudgeOnClickHandlers(
  nudges: NudgeObject[],
  setDrawerState: (state: DrawerState) => void,
  providerDashboardReferralUri: string,
  userClicked: (eventType: EventTypeEnum, payload: { nudgeId: number }) => void,
  isInSessionWithSelectedClient: boolean,
): Partial<Record<NudgeSchemaTrigger, () => void>> | null {
  if (!nudges.length) return null;

  const triggerHandlers: Partial<
    Record<NudgeSchemaTrigger, (nudgeId: number) => void>
  > = {
    // NOTE: This is a conditional handler that only gets defined if the provider is in session with the selected client
    ...(isInSessionWithSelectedClient
      ? {
          [NudgeSchemaTrigger.CLINICAL_RISK_REVIEW]: (nudgeId) => {
            setDrawerState(DrawerState.CSSRS_FORM);
            userClicked(EventTypeEnum.PROVIDER_NUDGE_CLICKED, { nudgeId });
          },
        }
      : {}),
    [NudgeSchemaTrigger.PSYCHIATRIC_MEDICATION_EVAL_WITHOUT_SUICIDALITY]: (
      nudgeId,
    ) => {
      window.open(
        providerDashboardReferralUri,
        "_blank",
        "noopener,noreferrer",
      );
      userClicked(EventTypeEnum.PROVIDER_NUDGE_CLICKED, { nudgeId });
    },
  };

  const nudgeHandlers = nudges
    .filter((nudge) => triggerHandlers[nudge.nudgeSchema.trigger] !== undefined)
    .map((nudge) => [
      nudge.nudgeSchema.trigger,
      () => triggerHandlers[nudge.nudgeSchema.trigger]!(nudge.id),
    ]);

  return nudgeHandlers.length === 0 ? null : Object.fromEntries(nudgeHandlers);
}
