import { useAtomCallback } from "jotai/utils";
import { audioRecordingEntitiesAtom, hasRecordingAtom } from "./state";
import { useUploadAudioCallback } from "./useUploadAudioCallback";
import { useCallback } from "react";
import { AudioRecorder } from "../../classes";
import { useSetAtom } from "jotai";
import { logger } from "../../datadog/logger";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { Mutex } from "async-mutex";
import { telehealthTranscriptionGate } from "../../statsig/gates";
import { isRecordingAtom } from "../../twilio/messages/state";
import { Statsig } from "../../statsig/StatsigProvider";

const controlAudioRecordingMutex = new Mutex();

/**
 * Returns a set of callbacks to record audio. This includes setting up the
 * audio recording environment, starting and stopping recording, and tearing
 * down the recording environment.
 *
 * IMPORTANT: In order for the recording to pick up the audio from all of the
 * participants, you'll need to use `useCombineAudioTracks` somewhere in the app
 * as well which will handle watch/combine audio tracks onto the destination
 * node created here.
 */
export function useRecordAudioCallbacks() {
  const getAudioRecordingEntities = useAtomCallback(
    useCallback((get) => get(audioRecordingEntitiesAtom), []),
  );
  const getIsRecording = useAtomCallback(
    useCallback((get) => get(isRecordingAtom).value, []),
  );
  const uploadAudio = useUploadAudioCallback();
  const setAudioRecordingEntities = useSetAtom(audioRecordingEntitiesAtom);
  const setHasRecording = useSetAtom(hasRecordingAtom);
  const setIsRecording = useSetAtom(isRecordingAtom);
  /**
   * If not already set up, then sets up the recording environment by creating
   * an audio context, the audio stream to record, and an audio recorder. It's
   * necessary to call this before starting recording.
   *
   * It's also important that this setup is done after some user interaction to
   * ensure that the audio context is not blocked by the browser.
   */
  const setUpRecording = useCallback(
    () => {
      const audioRecordingEntities = getAudioRecordingEntities();

      // Already set up
      if (audioRecordingEntities) return audioRecordingEntities;

      const audioContext = new AudioContext();
      const audioDestinationNode = audioContext.createMediaStreamDestination();
      const audioStreamToRecord = audioDestinationNode.stream;
      const audioRecorder = new AudioRecorder(audioStreamToRecord);

      sendLoggingEvents(
        TrackingEvents.SETTING_UP_AUDIO_RECORDING_ENV,
        {},
        {
          logLevel: "info",
          message: "Setting up audio recording environment",
        },
      );
      const audioEntities = {
        audioContext,
        audioDestinationNode,
        audioRecorder,
      };
      setAudioRecordingEntities(audioEntities);
      return audioEntities;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Clears the audio recording entities
   */
  const tearDownRecording = useCallback(
    async () => {
      const audioRecorder = getAudioRecordingEntities()?.audioRecorder;
      if (!audioRecorder) return;

      sendLoggingEvents(
        TrackingEvents.TEARING_DOWN_AUDIO_RECORDING_ENV,
        {},
        {
          logLevel: "info",
          message: "Tearing down audio recording environment",
        },
      );
      setAudioRecordingEntities(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const startRecording = useCallback(
    ({ lastUpdated }: { lastUpdated?: number } = {}) => {
      return controlAudioRecordingMutex.runExclusive(async () => {
        const isTranscriptionFeatureEnabled = Statsig.checkGate(
          telehealthTranscriptionGate,
        );
        if (!isTranscriptionFeatureEnabled) return;

        const isRecording = getIsRecording();
        if (isRecording) return;

        const { audioRecorder } = setUpRecording();
        sendLoggingEvents(
          TrackingEvents.STARTING_AUDIO_RECORDING,
          {},
          {
            logLevel: "info",
            message: "Starting audio recording",
          },
        );
        try {
          await audioRecorder.startRecording({
            onIntervalProc: async function uploadAudioChunk({
              audioBlob,
              recordingMetadata,
            }) {
              if (!audioBlob.size) {
                logger.debug("Audio blob size is 0, skipping upload");
                return;
              }

              await uploadAudio({
                audioBlob,
                recordingMetadata,
              });
            },
          });
          setHasRecording(true);
          setIsRecording({
            value: true,
            lastUpdated: lastUpdated || Date.now(),
          });
        } catch (error) {
          logger.error("Failed to start recording audio", {}, error as Error);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const stopRecording = useCallback(
    ({
      shouldTeardown,
      lastUpdated,
    }: { shouldTeardown?: boolean; lastUpdated?: number } = {}) => {
      return controlAudioRecordingMutex.runExclusive(async () => {
        const isTranscriptionFeatureEnabled = Statsig.checkGate(
          telehealthTranscriptionGate,
        );
        if (!isTranscriptionFeatureEnabled) return;

        const audioRecorder = getAudioRecordingEntities()?.audioRecorder;
        if (!audioRecorder) return;
        if (shouldTeardown) {
          await tearDownRecording();
        }

        const isRecording = getIsRecording();
        if (!isRecording) return;

        sendLoggingEvents(
          TrackingEvents.STOPPING_AUDIO_RECORDING,
          {},
          {
            logLevel: "info",
            message: "Stopping, getting, and uploading audio recording",
          },
        );
        try {
          const { audioBlob, recordingMetadata } =
            await audioRecorder.getRecording({
              shouldContinueRecordingAfter: false,
            });

          setIsRecording({
            value: false,
            lastUpdated: lastUpdated ?? Date.now(),
          });
          if (!audioBlob.size) return;
          // Do not await. Uploading the audio should be a fire-and-forget
          // side-effect.
          uploadAudio({ audioBlob, recordingMetadata });
        } catch (error) {
          logger.error(
            "Failed to stop, get, and upload audio recording",
            {},
            error as Error,
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    /**
     * Starts recording audio; will also set up the recording env first if not
     * already set up
     */
    startRecording,
    /**
     * Stops recording audio and uploads the audio recording to S3
     */
    stopRecording,
  };
}
