import { DEFAULT_APP_TITLE } from "./config";

/**
 * Creates the icon link and apple touch links if they don't exist.
 */
function initializeIconLinks() {
  let iconLink: HTMLLinkElement | null =
    document.querySelector("link[rel='icon']");
  let appleTouchLink: HTMLLinkElement | null = document.querySelector(
    "link[rel='apple-touch-icon']",
  );
  if (!iconLink) {
    iconLink = document.createElement("link");
    iconLink.rel = "icon";
    document.head.appendChild(iconLink);
  }
  if (!appleTouchLink) {
    appleTouchLink = document.createElement("link");
    appleTouchLink.rel = "apple-touch-icon";
    document.head.appendChild(appleTouchLink);
  }

  return { iconLink, appleTouchLink };
}

/**
 * Sets the favicon
 */
export function setFavicon(faviconPath: string) {
  if (faviconPath) {
    const { iconLink, appleTouchLink } = initializeIconLinks();
    iconLink.href = faviconPath;
    appleTouchLink.href = faviconPath;
  }
}

/**
 * Sets the document title
 */
export function setTitle(title: string) {
  if (title && typeof document !== "undefined") {
    document.title = title;
  }
}

/**
 * Sets the document title to the default app title
 */
export function setDefaultTitle() {
  if (typeof document !== "undefined") {
    document.title = DEFAULT_APP_TITLE;
  }
}

/**
 * Sets the favicon to the alert favicon
 */
export function setAlertFavicon() {
  setFavicon("/assets/favicon-alert.png");
}

/**
 * Sets the favicon to the default favicon
 */
export function setDefaultFavicon() {
  setFavicon("/assets/favicon.png");
}

/**
 * Sets the favicon and document title to the default values
 */
export function setDefaultFaviconAndTitle() {
  setDefaultFavicon();
  setDefaultTitle();
}

/**
 * Sets the favicon to the alert favicon and the document title to the given
 * title - useful for notifications like unread messages
 */
export function setAlertTitle(title: string) {
  setAlertFavicon();
  setTitle(title);
}
