import { useAtomValue } from "jotai";
import { TranscriptionPill } from "../../twilio/TranscriptionPill";
import { useGetProviderPatientConsent } from "./useGetProviderPatientConsent";
import { inSessionPatientInformationAtom, providerShortIdAtom } from "../state";
import { ConsentStatus } from "../../twilio/types";
import { useShouldDisableTranscriptionForMultipleParticipants } from "../../twilio/audio-video-controls/useShouldDisableTranscriptionForMultipleParticipants";
import { useRecordingControl } from "./useRecordingControl";

export function TranscriptionPillWrapper() {
  const { patientShortId } =
    useAtomValue(inSessionPatientInformationAtom) ?? {};
  const providerShortId = useAtomValue(providerShortIdAtom);
  const { isRecording, isVisible } = useRecordingControl();

  const { providerConsent, patientConsent } = useGetProviderPatientConsent(
    providerShortId,
    patientShortId,
  );

  const shouldDisableTranscriptionForMultipleParticipants =
    useShouldDisableTranscriptionForMultipleParticipants();

  if (
    !isVisible ||
    providerConsent !== ConsentStatus.OPTED_IN ||
    shouldDisableTranscriptionForMultipleParticipants
  ) {
    return null;
  }

  return (
    <TranscriptionPill
      isRecording={isRecording}
      patientConsent={patientConsent}
    />
  );
}
